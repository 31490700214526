import {
  HeartIcon,
  MagnifyingGlassIcon,
  UserCircleIcon,
  HomeIcon,
} from '@heroicons/react/24/outline';
import { AuthContext } from 'context/AuthContext';
import { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

interface NavItem {
  name: string;
  link?: string;
  icon: any;
}

const NAV: NavItem[] = [
  {
    name: 'Accueil',
    link: '/',
    icon: HomeIcon,
  },
  {
    name: 'Explorer',
    link: '/listing-stay',
    icon: MagnifyingGlassIcon,
  },
  {
    name: 'Connexion',
    link: '/login',
    icon: UserCircleIcon,
  },
];

const log_NAV: NavItem[] = [
  {
    name: 'Accueil',
    link: '/',
    icon: HomeIcon,
  },
  {
    name: 'Explorer',
    link: '/listing-stay',
    icon: MagnifyingGlassIcon,
  },
  {
    name: 'Wishlists',
    link: '/account-savelists',
    icon: HeartIcon,
  },
  {
    name: 'Profil',
    link: '/account',
    icon: UserCircleIcon,
  },
];

const FooterNav = () => {
  const userCtx = useContext(AuthContext);

  const user = userCtx?.user;

  const containerRef = useRef<HTMLDivElement>(null);

  const location = useLocation();

  const [tabs, setTabs] = useState<NavItem[]>(NAV);

  useEffect(() => {
    if (user) {
      setTabs(log_NAV);
    } else {
      setTabs(NAV);
    }
  }, [user]);

  return (
    <div
      ref={containerRef}
      className="FooterNav p-2 bg-white dark:bg-neutral-800 fixed top-auto bottom-0 inset-x-0 z-30 border-t border-neutral-300 dark:border-neutral-700 
      transition-transform duration-300 ease-in-out"
    >
      <div
        className={`w-full max-w-lg flex mx-auto text-sm text-center grid ${'grid-cols-' + tabs.length}`}
      >
        {/* MENU */}
        {tabs.map((item, index) => {
          const active = location.pathname === item.link;
          return item.link ? (
            <Link
              key={index}
              to={item.link}
              className={`flex flex-col col-1 items-center justify-between text-neutral-500 dark:text-neutral-300/90 ${
                active ? 'text-neutral-900 dark:text-neutral-100' : ''
              }`}
            >
              <item.icon
                className={`w-6 h-6 ${active ? 'text-red-600' : ''}`}
              />
              <span className="text-[11px] leading-none mt-1">{item.name}</span>
            </Link>
          ) : (
            <div
              key={index}
              className={`flex flex-col items-center justify-between text-neutral-500 dark:text-neutral-300/90 ${
                active ? 'text-neutral-900 dark:text-neutral-100' : ''
              }`}
            >
              <item.icon iconClassName="w-6 h-6" className={``} />
              <span className="text-[11px] leading-none mt-1">{item.name}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FooterNav;
