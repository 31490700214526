import { FC, useEffect } from 'react';
import Input from 'shared/Input/Input';
import { Link, useNavigate } from 'react-router-dom';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { useConfirmForgotByEmail } from 'api/queries';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import toast from 'react-hot-toast';
import Cookies from 'js-cookie';

export interface ForgotPasswordProps {
  className?: string;
}

interface IFormInput {
  email: string;
}

const ForgotPassword: FC<ForgotPasswordProps> = ({ className = '' }) => {
  const {
    mutateAsync: confirmForgotByEmail,
    isLoading,
    isSuccess,
    isError,
  } = useConfirmForgotByEmail();

  const { register, handleSubmit } = useForm<IFormInput>();

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      navigate(0);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      toast.error('Email ou mot de passe incorrect');
    }
  }, [isError]);

  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      navigate('/');
    }
  }, []);

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-10 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          <FormattedMessage id="password.forgot.title" />
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <form
            className="grid grid-cols-1 gap-6"
            onSubmit={handleSubmit((data) => {
              confirmForgotByEmail({
                email: data.email,
              });
            })}
          >
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                <FormattedMessage id="login.email" />
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1"
                {...register('email', { required: true })}
              />
            </label>

            {!isSuccess && !isLoading && (
              <ButtonPrimary type="submit">
                <FormattedMessage id="password.forgot.confirm-email" />
              </ButtonPrimary>
            )}
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            <FormattedMessage id="login.no-account" />{' '}
            <Link to="/signup">
              <u>
                <FormattedMessage id="login.signup" />
              </u>
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
