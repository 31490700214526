import { FC, useEffect } from 'react';
import Input from 'shared/Input/Input';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { Link, useNavigate } from 'react-router-dom';
import {
  useRegisterOrLoginUserByGoogle,
  useRegisterUserByEmail,
} from 'api/queries';
import { useForm, Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { z } from 'zod';
import toast from 'react-hot-toast';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import Cookies from 'js-cookie';
import ReactGA from 'react-ga4';

const validationSchema = z.object({
  email: z.string({ required_error: 'Adresse email requise' }).email({
    message: "Votre adresse email n'est pas valide",
  }),
  password: z.string({ required_error: 'Mot de passe email requise' }).min(8, {
    message: 'Votre mois de passe doit comporter au moins 8 caractères',
  }),
});

export interface SignUpProps {
  className?: string;
}

type FormData = z.infer<typeof validationSchema>;

const SignUp: FC<SignUpProps> = ({ className = '' }) => {
  const {
    mutateAsync: registerUserByEmail,
    isLoading: isLoadingRegisterEmail,
    isError: isErrorRegisterEmail,
    isSuccess: isSuccessRegisterEmail,
  } = useRegisterUserByEmail();

  const {
    mutateAsync: registerOrLoginUserByGoogle,
    isError: isErrorGoogle,
    isSuccess: isSuccessGoogle,
  } = useRegisterOrLoginUserByGoogle();

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: (values) => {
      try {
        validationSchema.parse(values);
        return { values, errors: {} };
      } catch (error: any) {
        return { values: {}, errors: error.flatten().fieldErrors };
      }
    },
  });

  useEffect(() => {
    ReactGA.event({
      category: 'User event',
      action: 'Email Signup',
    });
  }, [isSuccessRegisterEmail]);

  useEffect(() => {
    if (isSuccessGoogle) {
      navigate('/');
    }
  }, [isSuccessGoogle]);

  useEffect(() => {
    if (isErrorRegisterEmail || isErrorGoogle) {
      toast.error("Un problème est survenu lors de l'inscription");
    }
  }, [isErrorRegisterEmail, isErrorGoogle]);

  const responseMessage = (response: CredentialResponse) => {
    ReactGA.event({
      category: 'User event',
      action: 'Google Signup',
    });
    registerOrLoginUserByGoogle({ token: response.credential || '' });
  };
  const errorMessage = () => {};

  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      navigate('/');
    }
  }, []);

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-10 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          <FormattedMessage id="signup.title" />
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <form
            className="grid grid-cols-1 gap-6"
            onSubmit={handleSubmit((data) => {
              registerUserByEmail({
                email: data.email,
                password: data.password,
              });
            })}
          >
            {!isSuccessRegisterEmail && (
              <>
                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">
                    <FormattedMessage id="signup.email" />
                    <span className="text-red-600">*</span>
                  </span>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <Input
                        type="email"
                        placeholder="example@example.com"
                        className="mt-1"
                        {...field}
                      />
                    )}
                  />
                  {errors && errors.email && (
                    <p className="text-sm text-red-600">
                      {JSON.stringify(errors.email)}
                    </p>
                  )}
                </label>
                <label className="block">
                  <span className="flex items-center text-neutral-800 dark:text-neutral-200">
                    <FormattedMessage id="signup.password" />
                    <span className="text-red-600">*</span>
                  </span>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <Input
                        type="password"
                        className="mt-1"
                        {...field}
                        data-testid="password-input"
                      />
                    )}
                  />
                  {errors && errors.password && (
                    <p className="text-sm text-red-600">
                      {JSON.stringify(errors.password)}
                    </p>
                  )}
                </label>
              </>
            )}

            {!isSuccessRegisterEmail && !isLoadingRegisterEmail && (
              <ButtonPrimary type="submit">
                <FormattedMessage id="signup.submit" />
              </ButtonPrimary>
            )}

            {!isErrorRegisterEmail &&
              !isSuccessRegisterEmail &&
              isLoadingRegisterEmail && (
                <ButtonPrimary type="submit" disabled>
                  <svg
                    aria-hidden="true"
                    role="status"
                    className="inline w-4 h-4 mr-3 text-white animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                  <FormattedMessage id="signup.loading" />
                </ButtonPrimary>
              )}

            {!isErrorRegisterEmail &&
              isSuccessRegisterEmail &&
              !isLoadingRegisterEmail && (
                <ButtonPrimary type="submit" disabled>
                  <FormattedMessage id="signup.success" />
                </ButtonPrimary>
              )}
          </form>
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              <FormattedMessage id="login.or" />
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          <div className="grid gap-3 flex content-center justify-center">
            <GoogleLogin
              onSuccess={responseMessage}
              onError={errorMessage}
              size="large"
              shape="pill"
              logo_alignment="center"
              type="icon"
              text="signup_with"
            />
          </div>
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            <FormattedMessage id="signup.existing-account" />{' '}
            <Link to="/login">
              <u>
                <FormattedMessage id="signup.signin" />
              </u>
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
