import { FC, useEffect, useRef, useState, HTMLAttributes } from 'react';
import checkInViewIntersectionObserver from '../../utils/isInViewPortIntersectionObserver';
import PlaceIcon from './PlaceIcon';

export interface NcPictureProps extends HTMLAttributes<HTMLPictureElement> {
  containerClassName?: string;
  srcPng?: string;
  srcWebp?: string;
  srcAvif?: string;
  alt?: string;
  imgClassName?: string;
}

const NcPicture: FC<NcPictureProps> = ({
  containerClassName = '',
  srcPng = '',
  srcWebp = '',
  srcAvif = '',
  alt = 'nc-imgs',
  imgClassName = 'object-cover w-full h-full',
  ...args
}) => {
  let isMounted = false;
  const _containerRef = useRef<HTMLDivElement | null>(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  const _initActions = async () => {
    _checkInViewPort();
  };

  const _checkInViewPort = () => {
    if (!_containerRef.current) return;
    checkInViewIntersectionObserver({
      target: _containerRef.current,
      options: {
        root: null,
        rootMargin: '0%',
        threshold: 0,
      },
      freezeOnceVisible: true,
      callback: _imageOnViewPort,
    });
  };

  const _imageOnViewPort = () => {
    const image = new Image();
    image.src = srcAvif || srcWebp || srcPng; // Load the first available source
    image.addEventListener('load', _handleImageLoaded);
  };

  const _handleImageLoaded = () => {
    if (!isMounted) return;
    setImageLoaded(true);
  };

  useEffect(() => {
    isMounted = true;
    _initActions();
    return () => {
      isMounted = false;
    };
  }, [srcPng, srcWebp, srcAvif]);

  const renderLoadingPlaceholder = () => {
    return (
      <div
        className={`${imgClassName} flex items-center justify-center bg-neutral-200 dark:bg-neutral-6000 text-neutral-100 dark:text-neutral-500`}
      >
        <div className="h-2/4 max-w-[50%]">
          <PlaceIcon />
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-NcPicture ${containerClassName}`}
      data-nc-id="NcPicture"
      ref={_containerRef}
    >
      {imageLoaded ? (
        <picture {...args}>
          {srcAvif && <source srcSet={srcAvif} type="image/avif" />}
          {srcWebp && <source srcSet={srcWebp} type="image/webp" />}
          {srcPng && <source srcSet={srcPng} type="image/png" />}
          <img
            src={srcPng || srcWebp || srcAvif}
            className={imgClassName}
            alt={alt}
          />
        </picture>
      ) : (
        renderLoadingPlaceholder()
      )}
    </div>
  );
};

export default NcPicture;
