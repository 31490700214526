import { MapPinIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useState, useRef, FC } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { suggest } from '@esri/arcgis-rest-geocoding';
import { ApiKeyManager } from '@esri/arcgis-rest-request';

interface Props {
  onClick?: () => void;
  onChange?: (value: string) => void;
  className?: string;
  defaultValue?: string;
  headingText?: string;
  closeModal: () => void;
  onInputDone?: (value: string) => void;
  placeHolder?: string;
  desc?: string;
  divHideVerticalLineClass?: string;
  autoFocus?: boolean;
  register: UseFormRegister<any>;
  handleChange: (name: any, value: any) => any;
  value?: string;
}

const LocationInput: FC<Props> = ({
  className = '',
  headingText = 'Where to?',
  handleChange,
  value,
}) => {
  const containerRef = useRef(null);
  const inputRef = useRef(null);
  const [showPopover, setShowPopover] = useState(false);

  const renderSearchValue = (suggestions?: Array<any>) => {
    return (
      <>
        {suggestions?.map((item) => (
          <span
            onClick={() => {
              setShowPopover(false);
              handleChange('address', item.text);
            }}
            key={item.text}
            className="flex px-4 sm:px-6 items-center space-x-3 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
          >
            <span className="block text-neutral-400">
              <MapPinIcon className="h-4 w-4 sm:h-6 sm:w-6" />
            </span>
            <span className="block text-neutral-700 dark:text-neutral-200">
              {item.text}
            </span>
          </span>
        ))}
      </>
    );
  };

  const [suggestions, setSuggestions] = useState<any>([]);

  const apiKey =
    'AAPK720ff7fb33f64e709bd31614214b2a88_V60hwmVScQyPOdNTneLquTmfQC5hVClaIBQzQf3km9zNY3e9noO7e_r-gaYHqq1';
  const authentication = ApiKeyManager.fromKey(apiKey);

  const onChange = (e: any) => {
    handleChange('address', e.target.value);
    if (typeof value === 'string' && value.length >= 3) {
      setShowPopover(true);
    } else {
      setShowPopover(false);
    }
    suggest(`${e.target.value} france`, {
      authentication: authentication,
    }).then(async (response) => {
      setSuggestions(response.suggestions);
    });
  };

  return (
    <div className={`${className}`} ref={containerRef}>
      <div className="p-5">
        <span className="block font-semibold text-xl sm:text-2xl">
          {headingText}
        </span>
        <div className="relative mt-5">
          <input
            className={`block w-full bg-transparent border px-4 py-3 pr-12 border-neutral-900 dark:border-neutral-200 rounded-xl focus:ring-0 focus:outline-none text-base leading-none placeholder-neutral-500 dark:placeholder-neutral-300 truncate font-bold placeholder:truncate`}
            placeholder={'Recher une ville, une adresse ...'}
            value={value}
            onChange={onChange}
            ref={inputRef}
          />
          <span className="absolute right-2.5 top-1/2 -translate-y-1/2">
            <MagnifyingGlassIcon className="w-5 h-5 text-neutral-700 dark:text-neutral-400" />
          </span>
        </div>
        <div className="mt-7">
          {showPopover && renderSearchValue(suggestions)}
        </div>
      </div>
    </div>
  );
};

export default LocationInput;
