import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import NcPicture from 'shared/NcImage/NcPicture';

export interface SectionDiscoverProps {
  className?: string;
  rightImg?: string;
}

const SectionDiscover: FC<SectionDiscoverProps> = ({
  className = 'lg:py-2',
}) => {
  const intl = useIntl();

  return (
    <>
      <div
        className={`nc-SectionOurFeatures relative flex flex-col items-center lg:flex-row ${className}`}
        data-nc-id="SectionOurFeatures"
      >
        <div
          className={`max-w-2xl flex-shrink-0 mt-5 lg:mt-0 lg:w-2/5 lg:pl-16`}
        >
          <span className="uppercase text-sm text-gray-400 tracking-widest">
            <FormattedMessage id="home.discover.landlord.title" />
          </span>
          <h2 className="font-semibold text-3xl mt-5">
            <FormattedMessage id="home.discover.landlord.subtitle" />
          </h2>

          <ul className="space-y-10 mt-16">
            <li className="space-y-4">
              <span className="block text-xl font-semibold">
                <FormattedMessage id="home.discover.landlord.flexibility.title" />
              </span>
              <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
                <FormattedMessage id="home.discover.landlord.flexibility.subtitle" />
              </span>
            </li>
            <li className="space-y-4">
              <span className="block text-xl font-semibold">
                <FormattedMessage id="home.discover.landlord.mobility.title" />
              </span>
              <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
                <FormattedMessage id="home.discover.landlord.mobility.subtitle" />
              </span>
            </li>
            <li className="space-y-4">
              <span className="block text-xl font-semibold">
                <FormattedMessage id="home.discover.landlord.adaptability.title" />
              </span>
              <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
                <FormattedMessage id="home.discover.landlord.adaptability.subtitle" />
              </span>
            </li>
          </ul>
        </div>
        <div className="flex-grow">
          <NcPicture
            srcPng="https://d10qdsce7xisc3.cloudfront.net/static/home-3.png"
            srcWebp="https://d10qdsce7xisc3.cloudfront.net/static/home-3.webp"
            srcAvif="https://d10qdsce7xisc3.cloudfront.net/static/home-3.avif"
            alt={intl.formatMessage({ id: 'home.splash.img.alt' })}
            className="w-full"
          />
        </div>
      </div>
    </>
  );
};

export default SectionDiscover;
