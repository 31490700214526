import { FC, useEffect } from 'react';
import Checkbox from 'shared/Checkbox/Checkbox';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import {
  useAddAmenitiesToProperty,
  useCreateProperty,
  useListAmenities,
} from 'api/queries';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { EnumDays } from 'api/generated-api-client';

export interface AddAmenitiesPropertyProps {}

const AddAmenitiesProperty: FC<AddAmenitiesPropertyProps> = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const propertyId = params.get('id');

  const { data: amenities } = useListAmenities();

  const validationSchema = z.object({
    amenities: z.array(z.string()),
  });

  const intl = useIntl();

  type FormData = z.infer<typeof validationSchema>;

  const { control, handleSubmit, getValues, setValue } = useForm<FormData>({
    resolver: (values) => {
      try {
        validationSchema.parse(values);
        return { values, errors: {} };
      } catch (error: any) {
        return { values: {}, errors: error.flatten().fieldErrors };
      }
    },
  });
  const navigate = useNavigate();
  const handleCheckboxChange = (amenity: string, isChecked: boolean) => {
    const epureAmenity = amenity.replace(/^"(.*)"$/, '$1');
    const currentValues = getValues(); // Obtenir les valeurs actuelles du formulaire
    const updatedDays = isChecked
      ? [...currentValues.amenities, epureAmenity] // Ajouter le jour au tableau
      : currentValues.amenities.filter((d) => d !== epureAmenity); // Retirer le jour du tableau
    // Mettre à jour la valeur du champ "days" dans le formulaire
    setValue('amenities', updatedDays);
  };

  const { mutateAsync: addAmenitiesToProperty, data: updatedProperty } =
    useAddAmenitiesToProperty();

  useEffect(() => {
    if (updatedProperty) {
      navigate(`/listing-stay-detail?id=${propertyId}`);
    }
  }, [updatedProperty]);

  const enumMapper = (dayString: string): EnumDays => {
    switch (dayString.toLowerCase()) {
      case 'lundi':
        return EnumDays.MONDAY;
      case 'mardi':
        return EnumDays.TUESDAY;
      case 'mercredi':
        return EnumDays.WEDNESDAY;
      case 'jeudi':
        return EnumDays.THURSDAY;
      case 'vendredi':
        return EnumDays.FRIDAY;
      case 'samedi':
        return EnumDays.SATURDAY;
      case 'dimanche':
        return EnumDays.SUNDAY;
      default:
        return EnumDays.SUNDAY; // Retourne null si la chaîne ne correspond à aucun jour de la semaine
    }
  };

  return (
    <div
      className={`nc-PageAddListing1 px-4 max-w-3xl mx-auto pb-24 pt-14 sm:py-24 lg:pb-32`}
      data-nc-id="PageAddListing1"
    >
      <div className="space-y-11">
        <div>
          <span className="text-4xl font-semibold">
            Quels sont les services disponibles ?
          </span>
        </div>

        {/* --------------------- */}
        {amenities && amenities.length > 0 && (
          <div className="listingSection__wrap ">
            <form
              onSubmit={handleSubmit((data) => {
                console.log(propertyId, data.amenities);
                const result = addAmenitiesToProperty({
                  id: propertyId as string,
                  amenities: data.amenities,
                });
              })}
            >
              <div className="space-y-8">
                <div className="space-y-8">
                  {/* ITEM */}
                  <div>
                    {/* <label className="text-lg font-semibold" htmlFor="">
                      Jours disponibles
                    </label> */}
                    <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                      {amenities.map((amenity) => (
                        <div
                          key={amenity.id}
                          className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5"
                        >
                          <Controller
                            name="amenities" // Utilisez le même nom pour tous les jours
                            control={control}
                            defaultValue={[]} // Initialisez avec un tableau vide
                            render={({ field }) => (
                              <>
                                <Checkbox
                                  {...field}
                                  label={intl.formatMessage({
                                    id: `amenities.${amenity.label}`,
                                  })}
                                  onChange={(e) =>
                                    handleCheckboxChange(
                                      JSON.stringify(amenity.id),
                                      e,
                                    )
                                  }
                                />
                              </>
                            )}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {/* --------------------- */}
                <div className="flex justify-end space-x-5">
                  <ButtonPrimary type="submit">
                    {"Finaliser l'inscription"}
                  </ButtonPrimary>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddAmenitiesProperty;
