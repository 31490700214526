import { FC } from 'react';
import GallerySlider from 'components/GallerySlider/GallerySlider';
import { Link } from 'react-router-dom';
import BtnLikeIcon from 'components/BtnLikeIcon/BtnLikeIcon';
import Badge from 'shared/Badge/Badge';
import { AmenityDto } from 'api/generated-api-client';
import { StayCardProps } from 'components/StayCard/StayCard';

const StayCardH: FC<StayCardProps> = ({
  size = 'default',
  isPropertyBookmarked,
  className = '',
  onBookmarkClick,
  data: dataProp,
  displayBookmarkIcon,
}) => {
  const { id, name, street, city, amenities, assets, fleexoff, price_per_day } =
    dataProp;

  const images: string[] =
    assets && assets.length > 0
      ? assets?.map(
          (asset) => `https://d10qdsce7xisc3.cloudfront.net/${id}/${asset}`,
        )
      : [];

  const renderSliderGallery = () => {
    return (
      <div className="relative flex-shrink-0 w-full md:w-72 ">
        {id && images && images.length > 0 && (
          <GallerySlider
            ratioClass="aspect-w-6 aspect-h-5"
            galleryImgs={images}
            uniqueID={`StayCardH`}
            href={'/'}
            eventId={id as string}
          />
        )}
        {displayBookmarkIcon && (
          <BtnLikeIcon
            isLiked={isPropertyBookmarked}
            onClick={() => onBookmarkClick && onBookmarkClick(id)}
            className="absolute right-3 top-3 z-[1]"
          />
        )}{' '}
      </div>
    );
  };

  const renderTienIch = (amenities: AmenityDto[] | null) => {
    return <></>;
  };

  const renderContent = () => {
    return (
      <div className="flex-grow p-3 sm:p-5 flex flex-col">
        <div className="space-y-2">
          <div className="text-sm text-neutral-500 dark:text-neutral-400">
            <span>
              {street}, {city}
            </span>
          </div>
          <div className="flex items-center space-x-2">
            {fleexoff && <Badge name="Fleexoff" color="green" />}
            <h2 className="text-lg font-medium capitalize">
              <span className="line-clamp-1">{name}</span>
            </h2>
          </div>
        </div>
        <div className="hidden sm:block w-14 border-b border-neutral-100 dark:border-neutral-800 my-4"></div>
        {renderTienIch(amenities)}
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 my-4"></div>
        <div className="flex justify-between items-end">
          <div
            className={`nc-StartRating flex items-center space-x-1 text-sm  ${className}`}
            data-nc-id="StartRating"
          ></div>
          <span className="text-base font-semibold text-secondary-500">
            {price_per_day}€{` `}
            <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
              /jour
            </span>
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow will-change-transform ${className}`}
      data-nc-id="StayCardH"
    >
      <Link
        to={{ pathname: '/listing-stay-detail', search: `?id=${id}` }}
        className="absolute inset-0"
      ></Link>
      <div className="grid grid-cols-1 md:flex md:flex-row ">
        {renderSliderGallery()}
        {renderContent()}
      </div>
    </div>
  );
};

export default StayCardH;
