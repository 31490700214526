import { Fragment, useEffect, useState } from 'react';
import { Dialog, Tab, Transition } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { useTimeoutFn } from 'react-use';
import { SubmitHandler, useForm } from 'react-hook-form';
import LocationInput from './LocationInput';
import Checkbox from 'shared/Checkbox/Checkbox';
import { useNavigate } from 'react-router-dom';

interface IFormInput {
  address?: string;
  distance?: number;
  filters: {
    days: string[];
  };
}

const HeroSearchForm2Mobile = () => {
  const [showModal, setShowModal] = useState(false);
  const [dates, setDates] = useState<string[]>([
    'Lundi',
    'Mardi',
    'Mercredi',
    'Jeudi',
    'Vendredi',
    'Samedi',
    'Dimanche',
  ]);

  const { register, handleSubmit, watch, setValue } = useForm<IFormInput>({
    defaultValues: {
      address: '',
      distance: 10,
      filters: {
        days: dates,
      },
    },
  });

  const navigate = useNavigate();

  useEffect(() => {}, [dates]);

  const updateDates = (date: string, checked: boolean) => {
    if (checked) {
      setDates([...dates, date]);
    } else {
      setDates(dates.filter((item) => item !== date));
    }
  };

  // FOR RESET ALL DATA WHEN CLICK CLEAR BUTTON
  const [showDialog, setShowDialog] = useState(false);
  let [, , resetIsShowingDialog] = useTimeoutFn(() => setShowDialog(true), 1);
  //
  function closeModal() {
    setShowModal(false);
  }

  function openModal() {
    setShowModal(true);
  }

  const [fieldNameShow, setFieldNameShow] = useState<
    'locationPickup' | 'locationDropoff' | 'dates' | 'guests' | 'general'
  >('locationPickup');
  const locationInputPickUp = '';

  const renderInputLocationPickup = () => {
    const isActive = fieldNameShow === 'locationPickup';
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 ${
          isActive
            ? 'rounded-2xl shadow-lg'
            : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow('locationPickup')}
          >
            <span className="text-neutral-400">
              Où se trouve votre prochaine local ?
            </span>
            <span>{locationInputPickUp || 'Location'}</span>
          </button>
        ) : (
          <LocationInput
            headingText="Où se trouve votre prochaine local ?"
            register={register}
            handleChange={setValue}
            placeHolder="Localisation"
            desc="Chercher une ville, une adresse ..."
            className="flex-1"
            onClick={() => {}}
            value={watch('address')}
            closeModal={closeModal}
          />
        )}
      </div>
    );
  };

  const renderGenerals = () => {
    const isActive = fieldNameShow === 'general';
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? 'rounded-2xl shadow-lg'
            : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'
        }`}
      >
        {
          /*!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("general")}
          >
            <span className="text-neutral-400">Jours disponibles</span>
          </button>
        ) : */ <div className="p-5">
            <span className="block font-semibold text-xl sm:text-2xl">
              Jours disponibles
            </span>
            <div className="relative mt-5">
              <div className="mt-6">
                <div className="mt-4 grid grid-cols-1 sm:grid-cols-3 gap-4">
                  {/* {renderRadio("class", "Economy", "Economy")}
                  {renderRadio("class", "Business", "Business")}
                  {renderRadio("class", "Multiple", "Multiple")} */}
                  <div className="mt-6 grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-2 gap-5">
                    <Checkbox
                      label="Lundi"
                      name="Lundi"
                      defaultChecked={dates.includes('Lundi')}
                      onChange={(checked) => updateDates('Lundi', checked)}
                    />
                    <Checkbox
                      label="Mardi"
                      name="Mardi"
                      defaultChecked={dates.includes('Mardi')}
                      onChange={(checked) => updateDates('Mardi', checked)}
                    />
                    <Checkbox
                      label="Mercredi"
                      name="Mercredi"
                      defaultChecked={dates.includes('Mercredi')}
                      onChange={(checked) => updateDates('Mercredi', checked)}
                    />
                    <Checkbox
                      label="Jeudi"
                      name="Jeudi"
                      defaultChecked={dates.includes('Jeudi')}
                      onChange={(checked) => updateDates('Jeudi', checked)}
                    />
                    <Checkbox
                      label="Vendredi"
                      name="Vendredi"
                      defaultChecked={dates.includes('Vendredi')}
                      onChange={(checked) => updateDates('Vendredi', checked)}
                    />
                    <Checkbox
                      label="Samedi"
                      name="Samedi"
                      defaultChecked={dates.includes('Samedi')}
                      onChange={(checked) => updateDates('Samedi', checked)}
                    />
                    <Checkbox
                      label="Dimanche"
                      name="Dimanche"
                      defaultChecked={dates.includes('Dimanche')}
                      onChange={(checked) => updateDates('Dimanche', checked)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  };

  const renderButtonOpenModal = () => {
    return (
      <button
        onClick={openModal}
        className="relative flex items-center w-full border border-neutral-200 dark:border-neutral-6000 px-4 py-2 pr-11 rounded-full shadow-lg"
      >
        <MagnifyingGlassIcon className="flex-shrink-0 w-5 h-5" />

        <div className="ml-3 flex-1 text-left overflow-hidden">
          <span className="block font-medium text-sm">
            Où se trouve votre prochain local ?
          </span>
          <span className="block mt-0.5 text-xs font-light text-neutral-500 dark:text-neutral-400 line-clamp-1">
            Chercher une ville, une adresse ...
          </span>
        </div>

        <span className="absolute right-2 top-1/2 transform -translate-y-1/2 w-9 h-9 flex items-center justify-center rounded-full border border-neutral-200 dark:border-neutral-6000 dark:text-neutral-300">
          <svg
            viewBox="0 0 16 16"
            aria-hidden="true"
            role="presentation"
            focusable="false"
            className="block w-4 h-4"
            fill="currentColor"
          >
            <path d="M5 8c1.306 0 2.418.835 2.83 2H14v2H7.829A3.001 3.001 0 1 1 5 8zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm6-8a3 3 0 1 1-2.829 4H2V4h6.17A3.001 3.001 0 0 1 11 2zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
          </svg>
        </span>
      </button>
    );
  };

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    const encodedAddress = encodeURIComponent(data.address || '');
    const url = `/listing-stay-map?q=${encodedAddress}&distance=10${
      dates.length > 0
        ? dates.length >= 7
          ? ''
          : `&days=${dates.join(',')}`
        : ''
    }`;
    data.distance ? url.concat(`&distance=10`) : url.concat(`&distance=10`);

    navigate(url);
    closeModal();
  };

  return (
    <div className="HeroSearchForm2Mobile">
      {renderButtonOpenModal()}
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as="div"
          className="HeroSearchFormMobile__Dialog relative z-max"
          onClose={closeModal}
        >
          <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
            <div className="flex h-full">
              <Transition.Child
                as={Fragment}
                enter="ease-out transition-transform"
                enterFrom="opacity-0 translate-y-52"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in transition-transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-52"
              >
                <Dialog.Panel className="relative h-full overflow-hidden flex-1 flex flex-col justify-between ">
                  {showDialog && (
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <Tab.Group manual>
                        <div className="absolute left-4 top-4">
                          <button className="" onClick={closeModal}>
                            <XMarkIcon className="w-5 h-5 text-black dark:text-white" />
                          </button>
                        </div>
                        <div className="flex-1 pt-3 px-1.5 sm:px-4 flex overflow-hidden mt-5">
                          <Tab.Panels className="flex-1 overflow-y-auto hiddenScrollbar py-4">
                            <Tab.Panel>
                              <div className="transition-opacity animate-[myblur_0.4s_ease-in-out]">
                                <div>
                                  <div className="w-full space-y-5">
                                    {renderInputLocationPickup()}
                                    {renderGenerals()}
                                  </div>
                                </div>
                              </div>
                            </Tab.Panel>
                          </Tab.Panels>
                        </div>
                        <div className="px-4 py-3 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 flex justify-between">
                          <button
                            type="button"
                            className="underline font-semibold flex-shrink-0"
                            onClick={() => {
                              setShowDialog(false);
                              resetIsShowingDialog();
                              closeModal();
                            }}
                          >
                            Retour
                          </button>
                          <button
                            type="submit"
                            className={`flex-shrink-0 px-4 py-2.5 cursor-pointer rounded-xl bg-primary-6000 flex items-center justify-center text-neutral-50 focus:outline-none relative z-20`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={1.5}
                                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                              />
                            </svg>
                            <span className="ml-2">Rechercher</span>
                          </button>
                        </div>
                      </Tab.Group>
                    </form>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default HeroSearchForm2Mobile;
