import { useState } from 'react';
import { FC } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { MapPinIcon } from '@heroicons/react/24/outline';
import useOutsideAlerter from 'hooks/useOutsideAlerter';
import ButtonSubmit from './ButtonSubmit';
import { UseFormRegister } from 'react-hook-form';
import { suggest } from '@esri/arcgis-rest-geocoding';
import { ApiKeyManager } from '@esri/arcgis-rest-request';

export interface LocationInputProps {
  onInputDone?: (value: string) => void;
  placeHolder?: string;
  desc?: string;
  className?: string;
  divHideVerticalLineClass?: string;
  autoFocus?: boolean;
  onClick?: () => void;
  register: UseFormRegister<any>;
  handleChange: (name: any, value: any) => any;
  value?: string;
}

const LocationInput: FC<LocationInputProps> = ({
  autoFocus = false,
  onInputDone,
  placeHolder = 'Localisation',
  desc = 'Chercher une ville, une adresse ...',
  className = 'nc-flex-1.5',
  divHideVerticalLineClass = 'left-10 -right-0.5',
  onClick,
  register,
  handleChange,
  value,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [showPopover, setShowPopover] = useState(autoFocus);

  useEffect(() => {
    setShowPopover(autoFocus);
    if (autoFocus && !!inputRef.current) {
      setTimeout(() => {
        inputRef.current && inputRef.current.focus();
      }, 200);
    }
  }, [autoFocus]);

  useOutsideAlerter(containerRef, () => {
    setShowPopover(false);
  });

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  const renderSearchValue = (suggestions?: Array<any>) => {
    return (
      <>
        {suggestions?.map((item) => (
          <span
            onClick={() => {
              setShowPopover(false);
              handleChange('address', item.text);
            }}
            key={item.text}
            className="flex px-4 sm:px-6 items-center space-x-3 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
          >
            <span className="block text-neutral-400">
              <MapPinIcon className="h-4 w-4 sm:h-6 sm:w-6" />
            </span>
            <span className="block text-neutral-700 dark:text-neutral-200">
              {item.text}
            </span>
          </span>
        ))}
      </>
    );
  };

  const [suggestions, setSuggestions] = useState<any>([]);

  const apiKey =
    'AAPK720ff7fb33f64e709bd31614214b2a88_V60hwmVScQyPOdNTneLquTmfQC5hVClaIBQzQf3km9zNY3e9noO7e_r-gaYHqq1';
  const authentication = ApiKeyManager.fromKey(apiKey);

  const onChange = (e: any) => {
    handleChange('address', e.target.value);
    if (typeof value === 'string' && value.length >= 3) {
      setShowPopover(true);
    } else {
      setShowPopover(false);
    }
    suggest(`${e.target.value} france`, {
      authentication: authentication,
    }).then(async (response) => {
      setSuggestions(response.suggestions);
    });
  };

  return (
    <div className={`relative flex ${className}`} ref={containerRef}>
      <div
        onClick={() => setShowPopover(true)}
        className={`flex flex-1 relative z-10 [ nc-hero-field-padding--small ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left ${
          showPopover ? 'nc-hero-field-focused--2' : ''
        }`}
      >
        <div className="flex-1">
          <input
            className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-400 xl:text-base font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
            placeholder={placeHolder}
            autoFocus={showPopover}
            autoComplete="off"
            onChange={onChange}
            value={value}
          />
          <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
            <span className="line-clamp-1">{!!value ? placeHolder : desc}</span>
          </span>
        </div>
        <div className="">
          <ButtonSubmit onClick={onClick} />
        </div>
      </div>
      {showPopover && (
        <div className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[400px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-5 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
          {renderSearchValue(suggestions)}
        </div>
      )}
    </div>
  );
};

export default LocationInput;
