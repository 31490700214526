import { useContext } from 'react';
import { useMutation, useQuery } from 'react-query';
import { ApiClient } from './api-client.service';
import { useLocalTokens } from 'context/useLocalTokens';
import { ApiContext } from './ApiProvider';

export function useApiClient(): ApiClient {
  const ctx = useContext(ApiContext);

  if (!ctx) {
    throw new Error('Must be used within a ApiClientProvider');
  }

  return ctx.api;
}

export function useListProperties(page: number, limit: number) {
  const apiClient = useApiClient();
  const result = useQuery(
    ['properties', page, limit],
    () => {
      return apiClient.listProperties(page, limit);
    },
    { retryDelay: 0, retry: 1 },
  );
  const { data, error, isLoading } = result;

  return { data, error, isLoading };
}

export function useListAmenities() {
  const apiClient = useApiClient();
  const result = useQuery(
    ['amenities'],
    () => {
      return apiClient.listAmenities();
    },
    { retryDelay: 0, retry: 1 },
  );
  const { data, error, isLoading } = result;

  return { data, error, isLoading };
}

export function useGetProperty(id: string) {
  const apiClient = useApiClient();
  const result = useQuery(
    ['property', id],
    () => {
      return apiClient.getProperty(id);
    },
    { retryDelay: 0, retry: 1 },
  );
  const { data, error, isLoading } = result;

  return { data, error, isLoading };
}

export function useGetMonthlyDaysWithAvailabilityForProperty(
  propertyId: string,
  month: number,
) {
  const apiClient = useApiClient();
  const result = useQuery(
    ['availabilities', propertyId, month],
    () => {
      return apiClient.getMonthlyDaysWithAvailabilityForProperty(
        propertyId,
        month,
      );
    },
    { retryDelay: 0, retry: 1 },
  );
  const { data, error, isLoading } = result;

  return { data, error, isLoading };
}

export function useGetDailyAvailabilityForProperty(
  propertyId: string,
  date: Date,
) {
  const apiClient = useApiClient();
  const result = useQuery(
    ['slots', propertyId, date],
    () => {
      return apiClient.getDailyAvailabilityForProperty(propertyId, date);
    },
    { retryDelay: 0, retry: 1 },
  );
  const { data, error, isLoading } = result;

  return { data, error, isLoading };
}

export function useSearchPropertiesByDistance(
  address?: string,
  distance?: number,
  days?: string | null,
  lat?: string | null,
  lng?: string | null,
) {
  const cleanLng = lng ? lng : '0';
  const cleanLat = lat ? lat : '0';
  const cleanAddress = address ? address : '';
  const cleanDistance = distance ? distance : 0;

  const cleanDays = days ? days : '';

  const apiClient = useApiClient();
  const result = useQuery(
    ['search', address, distance, lat, lng],
    () => {
      return apiClient.searchPropertyByDistance(
        cleanLat,
        cleanLng,
        cleanAddress,
        cleanDistance,
        cleanDays,
      );
    },
    { retryDelay: 0, retry: 1 },
  );
  const { data, error, isLoading, refetch } = result;

  return { data, error, isLoading, refetch };
}

export function useRegisterUserByEmail() {
  const apiClient = useApiClient();
  return useMutation(apiClient.registerUserByEmail);
}

export function useRegisterOrLoginUserByGoogle() {
  const apiClient = useApiClient();
  return useMutation(apiClient.registerOrLoginUserByGoogle);
}

export function useAddAmenitiesToProperty() {
  const apiClient = useApiClient();
  return useMutation({ mutationFn: apiClient.updatePropertyAmenities });
}

export function useCreateProperty() {
  const apiClient = useApiClient();
  return useMutation({ mutationFn: apiClient.createProperty });
}

export function useUserInfo(enabled: boolean) {
  const apiClient = useApiClient();
  const { getLocalToken } = useLocalTokens();
  const token = getLocalToken();
  const result = useQuery(
    'userInfo',
    () => {
      return apiClient.getCurrentUserInfo({ Authorization: `Bearer ${token}` });
    },
    { retryDelay: 0, retry: 1, enabled },
  );
  const { data, error, isLoading, refetch } = result;

  return { data, error, isLoading, refetch };
}

export function useListProfession() {
  const apiClient = useApiClient();
  const { getLocalToken } = useLocalTokens();
  const token = getLocalToken();
  const result = useQuery(
    'listProfession',
    () => {
      return apiClient.getListProfessions({ Authorization: `Bearer ${token}` });
    },
    { retryDelay: 0, retry: 1 },
  );
  const { data, error, isLoading, refetch } = result;

  return { data, error, isLoading, refetch };
}

/**
 * Authentication queries
 */

export function useRefreshToken() {
  const apiClient = useApiClient();
  return useMutation({ mutationFn: apiClient.refreshToken });
}

export function useUpdateUser(callback?: {
  onSuccess: (response: any) => any;
}) {
  const apiClient = useApiClient();
  return useMutation(apiClient.updateUser, {
    onSuccess: (s) => {
      callback?.onSuccess(s);
    },
  });
}

export function useLogout() {
  const apiClient = useApiClient();
  return useMutation(apiClient.logout);
}

export function useLoginUserByEmail() {
  const apiClient = useApiClient();
  return useMutation(apiClient.loginUserByEmail);
}

export function useConfirmUserByEmail() {
  const apiClient = useApiClient();
  return useMutation(apiClient.confirmUserByEmail);
}

export function useConfirmForgotByEmail() {
  const apiClient = useApiClient();
  return useMutation(apiClient.confirmForgotByEmail);
}

export function useResetPassword() {
  const apiClient = useApiClient();
  return useMutation(apiClient.resetPassword);
}
