import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useConfirmUserByEmail } from 'api/queries';

export interface PageLoginProps {
  className?: string;
}

const PageConfirmEmail: FC<PageLoginProps> = ({ className = '' }) => {
  const { search } = useLocation();

  const params = new URLSearchParams(search);

  const hash = params.get('h')!;

  const {
    mutateAsync: registerUserByEmail,
    isLoading,
    isError,
    isSuccess,
  } = useConfirmUserByEmail();

  useEffect(() => {
    if (hash !== null) {
      registerUserByEmail({ hash: hash });
    }
  }, [hash]);

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-10 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Confirmation de votre email
        </h2>
        <h3 className="my-10 flex items-center text-1xl leading-[115%] md:text-3xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {isLoading && <>toto</>}
          {isError && (
            <div>
              <div>
                Une erreur est survenue ou votre e-mail est déjà confirmé
              </div>
              <div> Veuillez contacter le support</div>
            </div>
          )}
          {isSuccess && (
            <div>
              Votre e-mail est confirmé, vous pouvez dès à présent vous
              connecter
            </div>
          )}
        </h3>
      </div>
    </div>
  );
};

export default PageConfirmEmail;
