import React from 'react';
import Header from 'components/Header/Header';
import { useLocation } from 'react-router-dom';

export type SiteHeaders = 'Header 1' | 'Header 2' | 'Header 3';

const PAGES_HIDE_HEADER_BORDER: string[] = [
  '/home-3',
  '/listing-car-detail',
  '/listing-experiences-detail',
  '/listing-stay-detail',
];

const SiteHeader = () => {
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const isTopOfPage = window.pageYOffset < 5;
  const location = useLocation();

  const renderHeader = () => {
    let headerClassName = 'shadow-sm dark:border-b dark:border-neutral-700';
    if (PAGES_HIDE_HEADER_BORDER.includes(location.pathname as string)) {
      headerClassName = isTopOfPage
        ? ''
        : 'shadow-sm dark:border-b dark:border-neutral-700';
    }
    return <Header className={headerClassName} />;
  };

  return (
    <>
      {renderHeader()}
      <div ref={anchorRef} className="h-1 absolute invisible"></div>
    </>
  );
};

export default SiteHeader;
