import { FC } from 'react';
import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism';

export interface PageDataProps {
  className?: string;
}

const PageData: FC<PageDataProps> = ({ className = '' }) => {
  return (
    <div
      className="container py-16 lg:py-28 space-y-16 lg:space-y-18"
      data-nc-id="PageData"
    >
      <BgGlassmorphism />

      <div className="w-screen max-w-full xl:max-w-lg space-y-5 lg:space-y-7">
        <h2 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100">
          Politique de Confidentialité
        </h2>
        {/* <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
          We’re impartial and independent, and every day we create distinctive,
          world-class programmes and content which inform, educate and entertain
          millions of people in the around the world.
        </span> */}
      </div>

      <div className="nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between mb-12 lg:mb-16 text-neutral-900 dark:text-neutral-50">
        <div className="max-w-2xl">
          <h2 className="text-3xl md:text-4xl font-semibold">
            1. INFORMATIONS SUR L'ORGANISATION
          </h2>
          <span className="mt-2 md:mt-3 font-normal block text-base sm:text-xl text-neutral-500 dark:text-neutral-400">
            <p>Nom de l'entreprise : ACACIA SAS</p>
            <p>
              Adresse physique : 550 CHEMIN DE L'EPINE PIEU, 14340 AUVILLARS
            </p>
            <p>Coordonnées générales : contact@fleexoff.com</p>
          </span>
        </div>
      </div>

      <div className="nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between mb-12 lg:mb-16 text-neutral-900 dark:text-neutral-50">
        <div className="max-w-2xl">
          <h2 className="text-3xl md:text-4xl font-semibold">
            2. INFORMATIONS SUR L'ORGANISATION
          </h2>
          <span className="mt-2 md:mt-3 font-normal block text-base sm:text-xl text-neutral-500 dark:text-neutral-400">
            <p>
              Types de données personnelles collectées : nom, prénom, email,
              numéro de téléphone.
            </p>
            <p>
              Sources des données : directement auprès des utilisateurs et via
              des tiers.
            </p>
          </span>
        </div>
      </div>

      <div className="nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between mb-12 lg:mb-16 text-neutral-900 dark:text-neutral-50">
        <div className="max-w-2xl">
          <h2 className="text-3xl md:text-4xl font-semibold">
            3. FINALITÉS DE TRAITEMENT
          </h2>
          <span className="mt-2 md:mt-3 font-normal block text-base sm:text-xl text-neutral-500 dark:text-neutral-400">
            <p>
              Nous collectons et traitons vos données personnelles pour les
              finalités suivantes :
            </p>
            <ul className="list-disc pl-5">
              <li>
                Fournir des services : La base légale pour ce traitement est
                l'exécution d’un contrat.
              </li>
              <li>
                Marketing : La base légale pour ce traitement est le
                consentement implicite et l'intérêt légitime.
              </li>
              <li>
                Amélioration des produits : La base légale pour ce traitement
                est l'intérêt légitime.
              </li>
            </ul>
          </span>
        </div>
      </div>

      <div className="nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between mb-12 lg:mb-16 text-neutral-900 dark:text-neutral-50">
        <div className="max-w-2xl">
          <h2 className="text-3xl md:text-4xl font-semibold">
            4. DESTINATAIRES DES DONNÉES
          </h2>
          <span className="mt-2 md:mt-3 font-normal block text-base sm:text-xl text-neutral-500 dark:text-neutral-400">
            <p>
              Partenaires : Nous partageons occasionnellement vos données avec
              nos partenaires exerçant dans le même domaine que nous, et
              uniquement dans le cadre de relations B2B.
            </p>
            <p>
              Transfert hors de l’UE : Vos données ne sont pas transférées hors
              de l’UE.
            </p>
          </span>
        </div>
      </div>

      <div className="nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between mb-12 lg:mb-16 text-neutral-900 dark:text-neutral-50">
        <div className="max-w-2xl">
          <h2 className="text-3xl md:text-4xl font-semibold">
            5. DURÉE DE CONSERVATION
          </h2>
          <span className="mt-2 md:mt-3 font-normal block text-base sm:text-xl text-neutral-500 dark:text-neutral-400">
            <p>
              Durée de conservation des données : Nous conservons vos données
              personnelles pendant une durée de 3 ans.
            </p>
            <p>
              Critères utilisés pour déterminer la durée de conservation : Les
              données sont conservées tant qu’il n’y a pas d’activité du
              contact.
            </p>
          </span>
        </div>
      </div>

      <div className="nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between mb-12 lg:mb-16 text-neutral-900 dark:text-neutral-50">
        <div className="max-w-2xl">
          <h2 className="text-3xl md:text-4xl font-semibold">
            6. DROITS DES UTILISATEURS
          </h2>
          <span className="mt-2 md:mt-3 font-normal block text-base sm:text-xl text-neutral-500 dark:text-neutral-400">
            <p>
              Vous disposez des droits suivants concernant vos données
              personnelles :
            </p>
            <ul className="list-disc pl-5">
              <li>Droit d’accès</li>
              <li>Droit de rectification</li>
              <li>Droit à l’effacement</li>
              <li>Droit de limitation du traitement</li>
              <li>Droit à la portabilité des données</li>
              <li>Droit d’opposition</li>
            </ul>
            <p>
              Pour exercer ces droits, vous pouvez nous contacter par email à
              contact@fleexoff.com ou via un formulaire disponible sur notre
              site.
            </p>
          </span>
        </div>
      </div>

      <div className="nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between mb-12 lg:mb-16 text-neutral-900 dark:text-neutral-50">
        <div className="max-w-2xl">
          <h2 className="text-3xl md:text-4xl font-semibold">
            7. Sécurité des données
          </h2>
          <span className="mt-2 md:mt-3 font-normal block text-base sm:text-xl text-neutral-500 dark:text-neutral-400">
            <p>
              Nous mettons en œuvre les mesures de sécurité appropriées pour
              protéger vos données personnelles, notamment l'utilisation du CRM
              Axeptio.
            </p>
          </span>
        </div>
      </div>

      <div className="nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between mb-12 lg:mb-16 text-neutral-900 dark:text-neutral-50">
        <div className="max-w-2xl">
          <h2 className="text-3xl md:text-4xl font-semibold">
            8. MODIFICATIONS DE LA POLITIQUE DE CONFIDENTIALITÉ
          </h2>
          <span className="mt-2 md:mt-3 font-normal block text-base sm:text-xl text-neutral-500 dark:text-neutral-400">
            <p>
              En cas de modifications de notre politique de confidentialité,
              nous vous informerons par email.
            </p>
          </span>
        </div>
      </div>

      <div className="nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between mb-12 lg:mb-16 text-neutral-900 dark:text-neutral-50">
        <div className="max-w-2xl">
          <h2 className="text-3xl md:text-4xl font-semibold">
            9. INFORMATIONS SUPPLÉMENTAIRES
          </h2>
          <span className="mt-2 md:mt-3 font-normal block text-base sm:text-xl text-neutral-500 dark:text-neutral-400">
            <p>
              Pour toute information supplémentaire spécifique à notre secteur
              d’activité ou à la nature de nos services, n’hésitez pas à nous
              contacter à contact@fleexoff.com
            </p>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageData;
