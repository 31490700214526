import { FC, useEffect, useRef, useState } from 'react';
import StayCardH from 'components/StayCardH/StayCardH';
import { useSearchPropertiesByDistance } from 'api/queries';
import { useLocation } from 'react-router-dom';

export interface SectionGridHasMapProps {}

const SectionGridHasMap: FC<SectionGridHasMapProps> = () => {
  const { search } = useLocation();
  const searchRef = useRef(search);
  const [searchState, setSearchState] = useState(search);
  const params = new URLSearchParams(searchState);

  const { data, refetch } = useSearchPropertiesByDistance(
    params.get('q')!,
    parseInt(params.get('distance')!),
    params.get('days')!,
    params.get('lat'),
    params.get('lng'),
  );

  useEffect(() => {
    if (searchRef.current !== search) {
      setSearchState(search);
    }
  }, [search]);

  useEffect(() => {
    refetch();
  }, [searchState]);

  return (
    <div>
      <div className="relative flex min-h-screen">
        <div className="min-h-screen w-full xl:w-[780px] 2xl:w-[880px] flex-shrink-0 xl:px-8 ">
          <div className={`mb-12 lg:mb-16 `}>
            <h2 className="text-4xl font-semibold mt-5">{data?.focus.label}</h2>
          </div>
          <div className="grid grid-cols-1 gap-8">
            {data &&
              data.results.length > 0 &&
              data?.results.map((stay) => (
                <div key={stay.id}>
                  <StayCardH
                    size="small"
                    data={stay}
                    className="shadow-2xl"
                    key={stay.id}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionGridHasMap;
