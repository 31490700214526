import React, { FC, useContext, useEffect, useState } from 'react';
import StayCard from 'components/StayCard/StayCard';
import { PropertyDto } from 'api/generated-api-client';
import { AuthContext } from 'context/AuthContext';
import { useUpdateUser } from 'api/queries';

export interface SectionGridFilterCardProps {
  className?: string;
  data?: PropertyDto[];
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = '',
  data,
}) => {
  const userCtx = useContext(AuthContext);
  const user = userCtx?.user;
  const [currentBookmarks, setCurrentBookmarks]: any = useState();

  useEffect(() => {
    if (user) {
      userCtx.fetchUser();
      setCurrentBookmarks(user?.bookmarks);
    }
  }, [user]);
  const { mutate: updateUser } = useUpdateUser({
    onSuccess: (response) => {
      setCurrentBookmarks(response?.bookmarks);
    },
  });

  const onBookmarkClick = async (id: string) => {
    let newBookmarks: any;

    if (user?.id) {
      if (currentBookmarks.map((b: any) => b.id).includes(id)) {
        newBookmarks = currentBookmarks?.filter((b: any) => b.id !== id);
      } else {
        newBookmarks = currentBookmarks && [
          ...currentBookmarks,
          data?.find((e) => e.id === id),
        ];
      }

      updateUser({
        id: parseInt(user?.id),
        userUpdate: { bookmarks: newBookmarks },
      });
    }
  };

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      {/* <div className="mb-8 lg:mb-11">
        <TabFilters />
  </div>*/}
      <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {data &&
          data.length > 0 &&
          data?.map((stay) => (
            <StayCard
              key={stay.id}
              isPropertyBookmarked={currentBookmarks
                ?.map((b: any) => b.id)
                .includes(stay?.id)}
              data={stay}
              onBookmarkClick={onBookmarkClick}
              displayBookmarkIcon={user ? true : false}
            />
          ))}
      </div>
      {/* <div className="flex mt-16 justify-center items-center">
        <Pagination pageCnt={7} currentPage={2} />
        </div> */}
    </div>
  );
};

export default SectionGridFilterCard;
