import React, { FC } from "react";
import { Link } from "react-router-dom";

export interface AvatarProps {
  containerClassName?: string;
  sizeClass?: string;
  radius?: string;
  imgUrl?: string;
  userName?: string;
  hasChecked?: boolean;
  hasCheckedClass?: string;
}

const Avatar: FC<AvatarProps> = ({
  containerClassName = "ring-1 ring-white dark:ring-neutral-900",
  sizeClass = "h-6 w-6 text-sm",
  radius = "rounded-full",
  imgUrl = "https://google",
  userName,
  hasChecked,
  hasCheckedClass = "w-4 h-4 -top-0.5 -right-0.5",
}) => {
  const url = imgUrl || "";
  const name = userName || "John Doe";

  return (
    // <div
    //   className={`wil-avatar relative flex-shrink-0 inline-flex items-center justify-center text-neutral-100 uppercase font-semibold shadow-inner ${radius} ${sizeClass} ${containerClassName}`}
    //   style={{ backgroundColor: url ? undefined : _setBgColor(name) }}
    // >
    //   {url && (
    //     <img
    //       className={`absolute inset-0 w-full h-full object-cover ${radius}`}
    //       src={url}
    //       alt={name}
    //     />
    //   )}
    //   <span className="wil-avatar__name">{name[0]}</span>

    //   {hasChecked && (
    //     <span
    //       className={` bg-teal-500 rounded-full text-white text-xs flex items-center justify-center absolute  ${hasCheckedClass}`}
    //     >
    //       <i className="las la-check"></i>
    //     </span>
    //   )}
    // </div>
    <button
      className="
                hidden xl:inline-flex text-opacity-90
                group ml-2 px-4 py-2 border border-neutral-300 hover:border-neutral-400 dark:border-neutral-700 rounded-full items-center text-sm text-gray-700 dark:text-neutral-300 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50"
    >
      Mon espace
    </button>
  );
};

export default Avatar;
