import Label from "components/Label/Label";
import React from "react";
import { FC } from "react";

export interface FormItemProps {
  className?: string;
  label?: string;
  desc?: string;
  children?: React.ReactNode;
  errors?: any;
  id?: string;
}

const FormItem: FC<FormItemProps> = ({
  children,
  className = "",
  label,
  desc,
  errors,
  id,
}) => {
  return (
    <div className={className}>
      {label && <Label>{label}</Label>}
      <div className="mt-1">{children}</div>
      {desc && (
        <span className="block mt-3 text-xs text-neutral-500 dark:text-neutral-400 ">
          {desc}
        </span>
      )}

      {errors && id && errors[id] && (
        <p className="text-sm text-red-600">
          {errors[id].map((error: string) => (
            <div>{error}</div>
          ))}
        </p>
      )}
    </div>
  );
};

export default FormItem;
