import {
  ReactNode,
  useState,
  Fragment,
  useContext,
  useEffect,
  PropsWithChildren,
} from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { IntlProvider } from 'react-intl';
import { locales } from 'locales';
// import { useAuth } from "hooks/useAuth";
import { AuthContext, AuthProvider } from 'context/AuthContext';
import { GlobalStoreProvider } from 'global-store';
import { Toaster } from 'react-hot-toast';
import ReactGA from 'react-ga4';
import { ApiProvider } from 'api/ApiProvider';
import { ApiClientService } from 'api/api-client.service';
import { Page } from 'types';
import ScrollToTop from 'ScrollToTop';
import FooterNav from 'components/FooterNav';
import AccountPage from 'pages/AccountPage/AccountPage';
import AddAmenitiesProperty from 'pages/AddProperty/AddAmenitiesProperty';
import AddProperty from 'pages/AddProperty/AddProperty';
import ListingStayDetailPage from 'pages/ListingDetailPage/listing-stay-detail/ListingStayDetailPage';
import ListingStayMapPage from 'pages/ListingStayPage/ListingStayMapPage';
import ListingStayPage from 'pages/ListingStayPage/ListingStayPage';
import Login from 'pages/Login/Login';
import Page404 from 'pages/Page404/Page404';
import PageConfirmEmail from 'pages/PageConfirmEmail/PageConfirmEmail';
import PageContact from 'pages/PageContact/PageContact';
import PageHome from 'pages/PageHome/PageHome';
import ForgotPassword from 'pages/Password/Forgot';
import PageConfirmEmailForgot from 'pages/Password/PageConfirmEmailForgot';
import SignUp from 'pages/SignUp/SignUp';
import SiteHeader from 'pages/SiteHeader';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import Footer from 'shared/Footer/Footer';
import AccountSavelists from 'pages/AccountPage/AccountSavelists';
import PageData from 'pages/PageData/PageData';
import PageCGU from 'pages/PageCGU/PageCGU';
import CheckOutPagePageMain from 'pages/CheckOutPage/CheckOutPage';

export type Locale = 'fr' | 'en';
export type TranslateMessageFn = <T extends string | ReactNode = string>(
  code: string,
  values?: Record<string, string>,
) => T;

const locale: Locale = 'fr';

export const pages: Page[] = [
  { path: '/', exact: true, component: PageHome, label: 'Accueil' },
  { path: '/#', exact: true, component: PageHome, label: 'Accueil' },
  //
  {
    path: '/listing-stay',
    component: ListingStayPage,
    label: 'Liste des espaces Fleexoff',
  },
  {
    path: '/listing-stay-map',
    component: ListingStayMapPage,
    label: 'Carte des espaces Fleexoff',
  },
  {
    path: '/listing-stay-detail',
    component: ListingStayDetailPage,
    label: "Détail d'un fleexoff",
  },
  {
    path: '/listing-stay-detail/gallery',
    component: ListingStayDetailPage,
    label: "Photos d'un fleexoff",
  },
  //
  { path: '/account', component: AccountPage, label: 'Compte' },
  { path: '/account-savelists', component: AccountSavelists, label: 'Favoris' },
  {
    path: '/booking',
    component: CheckOutPagePageMain,
    label: 'Booking',
  },

  //
  { path: '/contact', component: PageContact, label: 'Contact' },
  { path: '/data', component: PageData, label: 'Données' },
  { path: '/cgu', component: PageCGU, label: 'CGU' },

  /* Session */
  { path: '/signup', component: SignUp, label: 'Inscription' },
  { path: '/login', component: Login, label: 'Connexion' },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    label: 'Mot de passe oublié',
  },
  {
    path: '/password-change',
    component: PageConfirmEmailForgot,
    label: 'Confirmation de changement de mot de passe',
  },

  {
    path: '/confirm-email',
    component: PageConfirmEmail,
    label: "Confirmation d'email",
  },

  /* Add new property form */
  {
    path: '/add-property',
    component: AddProperty,
    label: "Ajout d'un fleexoff",
  },
  {
    path: '/add-property-amenities',
    component: AddAmenitiesProperty,
    label: 'Ajout des commodités',
  },
];

const GoogleAnalyticsProvider = ({ children }: PropsWithChildren) => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname,
      title: pages.find((p) => p.path === location.pathname)?.label || '',
    });
  }, [location]);

  return <>{children}</>;
};

function App() {
  const [queryClient] = useState(
    new QueryClient({
      defaultOptions: {
        queries: { refetchOnWindowFocus: false, retry: false },
      },
    }),
  );

  ReactGA.initialize('G-8TQRV505K1');

  const api = new ApiClientService(`${process.env.REACT_APP_API_URL}`);

  let WIN_WIDTH = useWindowSize().width;
  if (typeof window !== 'undefined') {
    WIN_WIDTH = WIN_WIDTH || window.innerWidth;
  }

  const userCtx = useContext(AuthContext);

  return (
    <ApiProvider api={api}>
      <QueryClientProvider client={queryClient}>
        <IntlProvider
          locale={locale}
          messages={locales[locale]}
          defaultLocale="fr"
          textComponent={Fragment}
        >
          <GlobalStoreProvider>
            <AuthProvider>
              <Toaster position="top-right" />
              <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
                <BrowserRouter>
                  {/* Listen location pathname change and use Window to scroll to top*/}
                  <ScrollToTop />

                  <SiteHeader />

                  <Routes>
                    {pages.map(({ component, path, admin }) => {
                      const Component = component;
                      const user: { role: { id: number } } | null =
                        userCtx?.user;
                      if (admin) {
                        if (!user || !user.role || user.role.id !== 1) {
                          return (
                            <Route
                              key={'/'}
                              element={<PageHome />}
                              path={'/'}
                            />
                          );
                        }
                      }
                      return (
                        <Route
                          key={path}
                          element={
                            <GoogleAnalyticsProvider>
                              <Component />
                            </GoogleAnalyticsProvider>
                          }
                          path={path}
                        />
                      );
                    })}
                    <Route element={<Page404 />} />
                  </Routes>

                  <div className="mt-6"></div>
                  {WIN_WIDTH < 768 && <FooterNav />}
                  <Footer />
                </BrowserRouter>{' '}
              </div>
            </AuthProvider>
          </GlobalStoreProvider>
        </IntlProvider>
      </QueryClientProvider>
    </ApiProvider>
  );
}

export default App;
