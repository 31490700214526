import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import NcPicture from 'shared/NcImage/NcPicture';

export interface SlashSectionProps {
  className?: string;
}

const SplashSection: FC<SlashSectionProps> = ({ className = '' }) => {
  const navigate = useNavigate();
  const intl = useIntl();

  return (
    <div className={`flex flex-col-reverse lg:flex-col relative ${className}`}>
      <div className="flex flex-col mt-10 ld:mt-0 lg:flex-row lg:items-center">
        <div className="flex-shrink-0 lg:w-1/2 flex flex-col justify-center items-center lg:items-start space-y-8 sm:space-y-10 pb-14  xl:pr-14 lg:mr-10 xl:mr-0">
          <h2 className="font-medium text-4xl md:text-5xl xl:text-7xl !leading-[114%] text-center lg:text-start">
            <FormattedMessage id="home.splash.title" />
          </h2>
          <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
            <FormattedMessage id="home.splash.subtitle" />
          </span>

          <ButtonPrimary
            onClick={() => {
              navigate('/listing-stay');
            }}
          >
            <FormattedMessage id="home.splash.button.label" />
          </ButtonPrimary>
        </div>
        <NcPicture
          srcPng="https://d10qdsce7xisc3.cloudfront.net/static/home.png"
          srcWebp="https://d10qdsce7xisc3.cloudfront.net/static/home.webp"
          srcAvif="https://d10qdsce7xisc3.cloudfront.net/static/home.avif"
          alt={intl.formatMessage({ id: 'home.splash.img.alt' })}
          className="w-full"
        />
      </div>
    </div>
  );
};

export default SplashSection;
