import { FC, useContext, useEffect } from 'react';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import Input from 'shared/Input/Input';
import CommonLayout from './CommonLayout';
import { AuthContext } from 'context/AuthContext';
import { useUpdateUser } from 'api/queries';
import { z } from 'zod';
import { Controller, useForm } from 'react-hook-form';
import FormItem from 'pages/AddProperty/FormItem';
import toast from 'react-hot-toast';

export interface AccountPageProps {
  className?: string;
}

const AccountPage: FC<AccountPageProps> = ({ className = '' }) => {
  const validationSchema = z.object({
    firstname: z
      .string({ required_error: 'Un prénom est requis' })
      .min(1, { message: 'Un prénom valide est requis' }),
    lastname: z
      .string({ required_error: 'Une nom est requis' })
      .min(1, { message: 'Un nom valide est requis' }),
    email: z.string({ required_error: 'Une adresse email est requise' }).email({
      message: "Votre adresse email n'est pas valide",
    }),
    phone: z
      .string({ required_error: 'Un numéro de téléphone est requis' })
      .min(10, { message: "Votre numéro de téléphone n'est pas valide" }),
    siren: z
      .string({ required_error: 'Un numéro de siren est requis' })
      .min(14, { message: "Votre numéro de siren n'est pas valide" }),
    profession: z.string({ required_error: 'Une profession est requise' }),
  });

  const userCtx = useContext(AuthContext);
  const user = userCtx?.user;

  type FormData = z.infer<typeof validationSchema>;

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormData>({
    resolver: (values) => {
      try {
        validationSchema.parse(values);
        return { values, errors: {} };
      } catch (error: any) {
        return { values: {}, errors: error.flatten().fieldErrors };
      }
    },
  });

  useEffect(() => {
    setValue('firstname', user?.firstName || '');
    setValue('lastname', user?.lastName || '');
    setValue('email', user?.email || '');
    setValue('phone', user?.phone || '');
    setValue('siren', user?.siren || '');
    setValue('profession', user?.profession?.id.toString() || '0');
  }, [user]);

  const { mutate: updateUser } = useUpdateUser({
    onSuccess: (e) => {
      toast.success('Profil mis à jour avec succès');
    },
  });

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Profil</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex flex-col md:flex-row">
            <form
              onSubmit={handleSubmit(async (data) => {
                updateUser({
                  id: parseInt(user?.id as string),
                  userUpdate: {
                    firstName: data.firstname,
                    lastName: data.lastname,
                    email: data.email,
                    phone: data.phone,
                    siren: data.siren,
                    professionId: parseInt(data.profession),
                  },
                });
              })}
            >
              <div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-6">
                <div>
                  <FormItem label="Prénom" id="firstname" errors={errors}>
                    <Controller
                      name="firstname"
                      control={control}
                      render={({ field }) => (
                        <Input type="text" className="mt-1" {...field} />
                      )}
                    />
                  </FormItem>
                </div>
                <div>
                  <FormItem label="Nom" id="lastname" errors={errors}>
                    <Controller
                      name="lastname"
                      control={control}
                      render={({ field }) => (
                        <Input type="text" className="mt-1" {...field} />
                      )}
                    />
                  </FormItem>
                </div>
                {/* ---- */}
                <div>
                  <FormItem label="Adresse email" id="email" errors={errors}>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <Input type="email" className="mt-1" {...field} />
                      )}
                    />
                  </FormItem>
                </div>
                {/* ---- */}
                <div>
                  <FormItem label="Téléphone" id="phone" errors={errors}>
                    <Controller
                      name="phone"
                      control={control}
                      render={({ field }) => (
                        <Input type="text" className="mt-1" {...field} />
                      )}
                    />
                  </FormItem>
                </div>
                {/* ---- */}
                {/* <div>
                  <FormItem label="Profession" id="profession" errors={errors}>
                    <Controller
                      name="profession"
                      control={control}
                      defaultValue={'0'}
                      render={({ field }) => (
                        <Select
                          className="mt-1.5"
                          {...field}
                          onChange={(e: any) => {
                            setValue('profession', e.target.value.toString());
                          }}
                        >
                          <option value="0" selected disabled hidden>
                            Sélectionnez votre profession
                          </option>

                          {professions?.map((profession) => (
                            <option value={profession.id.toString()}>
                              {profession.label}
                            </option>
                          ))}
                        </Select>
                      )}
                    />
                  </FormItem>
                </div> */}
                {/* ---- */}
                <div>
                  <FormItem label="siren" id="siren" errors={errors}>
                    <Controller
                      name="siren"
                      control={control}
                      render={({ field }) => (
                        <Input type="text" className="mt-1" {...field} />
                      )}
                    />
                  </FormItem>
                </div>
                <div className="pt-2">
                  <ButtonPrimary type="submit">Sauvegarder</ButtonPrimary>
                </div>
              </div>
            </form>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPage;
