import {
  AmenityDto,
  Api,
  AvailableDaysDto,
  LoginResultDTO,
  Profession,
  PropertyDto,
  PropertyPayloadDTO,
  SearchResultsDto,
  UpdateUserDto,
  User,
} from './generated-api-client';
import Cookies from 'js-cookie';
import { useLocalTokens } from 'context/useLocalTokens';

export interface ApiClient {
  listProperties: (page: number, limit: number) => Promise<PropertyDto[]>;
  getProperty: (id: string) => Promise<PropertyDto>;
  getMonthlyDaysWithAvailabilityForProperty: (
    propertyId: string,
    month: number,
  ) => Promise<AvailableDaysDto>;
  getDailyAvailabilityForProperty: (
    propertyId: string,
    date: Date,
  ) => Promise<AvailableDaysDto>;
  searchPropertyByDistance: (
    lat: string,
    lng: string,
    address?: string,
    distance?: number,
    days?: string,
  ) => Promise<SearchResultsDto>;
  registerUserByEmail: (
    registerUserByEmail: RegisterUserByEmailDto,
  ) => Promise<void>;

  registerOrLoginUserByGoogle: (
    registerOrLoginUserByGoogle: RegisterOrLoginUserByGoogleDto,
  ) => Promise<LoginResultDTO>;

  loginUserByEmail: (
    loginUserByEmail: LoginUserByEmailDto,
  ) => Promise<LoginResultDTO>;

  confirmUserByEmail: (
    confirmUserByEmail: ConfirmUserByEmailDto,
  ) => Promise<void>;

  confirmForgotByEmail: (
    confirmForgotByEmail: ConfirmForgotByEmailDto,
  ) => Promise<void>;

  resetPassword: (resetPassword: ResetPasswordDto) => Promise<void>;

  refreshToken: (token: string) => Promise<Omit<LoginResultDTO, 'user'>>;
  logout: () => Promise<void>;
  getCurrentUserInfo: (headers: HeadersInit) => Promise<User>;
  getListProfessions: (headers: HeadersInit) => Promise<Profession[]>;
  updatePropertyAmenities: (update: {
    id: string;
    amenities: string[];
  }) => Promise<any>;
  updateUser: (update: {
    id: number;
    userUpdate: UpdateUserDto;
  }) => Promise<any>;
  createProperty: (property: PropertyPayloadDTO) => Promise<any>;
  listAmenities: () => Promise<AmenityDto[]>;
}

export interface EditedUser extends UpdateUserDto {
  id: number;
}
export interface RegisterUserByEmailDto {
  email: string;
  password: string;
}

export interface RegisterOrLoginUserByGoogleDto {
  token: string;
}

export interface LoginUserByEmailDto {
  email: string;
  password: string;
}

export interface ConfirmUserByEmailDto {
  hash: string;
}

export interface ConfirmForgotByEmailDto {
  email: string;
}

export interface ResetPasswordDto {
  hash: string;
  password: string;
}

export class ApiClientService implements ApiClient {
  private readonly apiClient: Api<unknown>;

  constructor(baseUrl: string) {
    this.apiClient = new Api({ baseUrl });
  }

  private getRequestHeaders = () => {};

  getListProfessions = async (headers: HeadersInit) => {
    const response = await this.apiClient.api.usersControllerListProfessions({
      headers: headers,
    });
    return response.data;
  };

  listProperties = async (page: number, limit: number) => {
    const response = await this.apiClient.api.propertiesControllerFindAll({
      page,
      limit,
    });
    return response.data;
  };

  getProperty = async (id: string) => {
    const response = await this.apiClient.api.propertiesControllerFindOne(id);
    return response.data;
  };

  listAmenities = async () => {
    const response =
      await this.apiClient.api.propertiesControllerListAllAmenities();
    return response.data;
  };

  getMonthlyDaysWithAvailabilityForProperty = async (
    propertyId: string,
    month: number,
  ) => {
    const response = await this.apiClient.api.availabilitiesControllerFindAll({
      month,
      property: propertyId,
    });
    return response.data;
  };

  getDailyAvailabilityForProperty = async (propertyId: string, date: Date) => {
    const stringDate = date.toISOString().split('T')[0];
    const response = await this.apiClient.api.availabilitiesControllerFindOne({
      date: stringDate,
      property: propertyId,
    });
    return response.data;
  };

  searchPropertyByDistance = async (
    lat: string,
    lng: string,
    address?: string,
    distance?: number,
    days?: string,
  ) => {
    const addressString = address ? address : '';
    const distanceNumber = distance ? distance : 0;
    const daysString = days ? days : '';

    const response = await this.apiClient.api.propertiesControllerFindByAdress({
      address: addressString,
      distance: distanceNumber,
      lat: lat,
      lng: lng,
      days: daysString,
    });
    return response.data;
  };

  registerOrLoginUserByGoogle = async (
    registerOrLoginUserByGoogle: RegisterOrLoginUserByGoogleDto,
  ) => {
    const response = await this.apiClient.api.authGoogleControllerLogin({
      idToken: registerOrLoginUserByGoogle.token,
    });

    if (response.data) {
      Cookies.set('token', response.data.token);
      Cookies.set('refreshToken', response.data.refreshToken);
      Cookies.set('tokenExpires', response.data.tokenExpires.toString());
    }
    return response.data;
  };

  registerUserByEmail = async (registerUserByEmail: RegisterUserByEmailDto) => {
    const response = await this.apiClient.api.authControllerRegister({
      email: registerUserByEmail.email,
      password: registerUserByEmail.password,
    });
    return response.data;
  };

  loginUserByEmail = async (loginUserByEmail: LoginUserByEmailDto) => {
    const response = await this.apiClient.api.authControllerLogin({
      email: loginUserByEmail.email,
      password: loginUserByEmail.password,
    });

    if (response.data) {
      Cookies.set('token', response.data.token);
      Cookies.set('refreshToken', response.data.refreshToken);
      Cookies.set('tokenExpires', response.data.tokenExpires.toString());
    }
    return response.data;
  };

  confirmUserByEmail = async (confirmUserByEmail: ConfirmUserByEmailDto) => {
    const response = await this.apiClient.api.authControllerConfirmEmail({
      hash: confirmUserByEmail.hash,
    });
    return response.data;
  };

  confirmForgotByEmail = async (
    confirmForgotByEmail: ConfirmForgotByEmailDto,
  ) => {
    const response = await this.apiClient.api.authControllerForgotPassword({
      email: confirmForgotByEmail.email,
    });
    return response.data;
  };

  resetPassword = async (resetPassword: ResetPasswordDto) => {
    const response = await this.apiClient.api.authControllerResetPassword({
      hash: resetPassword.hash,
      password: resetPassword.password,
    });
    return response.data;
  };

  refreshToken = async (token: string) => {
    const response = await this.apiClient.api.authControllerRefresh({
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  };

  logout = async () => {
    Cookies.remove('token');
    Cookies.remove('tokenExpires');
    Cookies.remove('refreshToken');

    const response = await this.apiClient.api.authControllerLogout();
    return response.data;
  };

  createProperty = async (property: PropertyPayloadDTO) => {
    const response =
      await this.apiClient.api.propertiesControllerCreateProperty(
        { ...property },
        { format: 'text' },
      );
    return response.data;
  };

  updatePropertyAmenities = async (update: {
    id: string;
    amenities: string[];
  }) => {
    const response =
      await this.apiClient.api.propertiesControllerUpdateAmenities(
        update.id,
        { amenities: update.amenities },
        { format: 'text' },
      );
    return response.data;
  };

  updateUser = async (update: { id: number; userUpdate: UpdateUserDto }) => {
    const { getLocalToken } = useLocalTokens();
    const token = getLocalToken();

    const response = await this.apiClient.api.usersControllerUpdate(
      update.id,
      update.userUpdate,
      { headers: { Authorization: `Bearer ${token}` } },
    );
    return response.data;
  };

  getCurrentUserInfo = async (headers: HeadersInit) => {
    const response = await this.apiClient.api.authControllerMe({
      headers: headers,
    });
    return response.data;
  };
}
