'use client';

import { FC } from 'react';
import SearchForm from './FlightSearchForm/FlightSearchForm';

export type SearchTab = 'Stays' | 'Experiences' | 'Cars' | 'Flights';

export interface SearchFormSmallProps {
  className?: string;
  defaultTab?: SearchTab;
  onTabChange?: (tab: SearchTab) => void;
  defaultFieldFocus?: any;
}

const SearchFormSmall: FC<SearchFormSmallProps> = ({ className = '' }) => {
  return (
    <div
      className={`nc-HeroSearchFormSmall ${className}`}
      data-nc-id="HeroSearchFormSmall"
    >
      <div className="h-[20px] flex justify-center space-x-5 sm:space-x-9"></div>
      <div className="mt-2">{<SearchForm />}</div>
    </div>
  );
};

export default SearchFormSmall;
