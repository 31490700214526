import { useRefreshToken, useUserInfo } from 'api/queries';
import { PropsWithChildren, createContext, useEffect, useState } from 'react';
import { useLocalTokens } from './useLocalTokens';
import { PropertyDto } from 'api/generated-api-client';

export const AuthContext = createContext<{
  user: {
    role: { id: number };
    email: string;
    firstName?: string;
    lastName?: string;
    id: string;
    phone?: string;
    siren?: string;
    profession?: { id: string; label: string };
    bookmarks?: PropertyDto[];
  } | null;
  fetchUser: () => void;
}>({
  user: null,
  fetchUser: () => {},
});

export function AuthProvider({ children }: PropsWithChildren) {
  /* Retrieve new token if expired method*/
  const { mutateAsync: refreshNewToken, data: refreshTokenData } =
    useRefreshToken();

  const {
    getLocalToken,
    getLocalTokenExpires,
    getLocalRefreshToken,
    removeLocalToken,
    removeAllLocalTokens,
    setLocalToken,
    setLocalRefreshToken,
    setLocalTokenExpires,
  } = useLocalTokens();

  const [token, setToken] = useState<string | undefined>(getLocalToken());
  const [tokenExpires, setTokenExpires] = useState<string | undefined>(
    getLocalTokenExpires(),
  );
  const [refreshToken, setRefreshToken] = useState<string | undefined>(
    getLocalRefreshToken(),
  );

  const [user, setUser] = useState<any | null>(null);

  const isTokenExpired = () => {
    if (!token || !tokenExpires) return true;

    return new Date().getTime() > new Date(parseInt(tokenExpires)).getTime();
  };

  if (!token || !tokenExpires || !refreshToken) {
    /* If one of the tokens is missing, it should properly logout user, kill session and delete traces */
    removeAllLocalTokens();
    /**
     *
     *  TODO : LOGOUT USER
     *
     */
  }

  if (refreshToken && isTokenExpired()) {
    removeLocalToken();
    refreshNewToken(refreshToken.toString());
  }

  const { data, refetch } = useUserInfo(isTokenExpired() ? false : true);

  useEffect(() => {
    if (data && user !== data) {
      setUser(data);
    }
  }, [data]);

  useEffect(() => {
    if (refreshTokenData) {
      setToken(refreshTokenData.token);
      setTokenExpires(refreshTokenData.tokenExpires.toString());
      setRefreshToken(refreshTokenData.refreshToken);
      setLocalToken(refreshTokenData.token);
      setLocalTokenExpires(refreshTokenData.tokenExpires.toString());
      setLocalRefreshToken(refreshTokenData.refreshToken);
    }
  }, [refreshTokenData]);

  const fetchUser = () => {
    if (token && !isTokenExpired()) {
      refetch();
    }
  };

  /* Check if token exists */

  return (
    <AuthContext.Provider value={{ user, fetchUser }}>
      {children}
    </AuthContext.Provider>
  );
}
