import { FC, useEffect } from 'react';
import Input from 'shared/Input/Input';
import Select from 'shared/Select/Select';
import FormItem from './FormItem';
import Textarea from 'shared/Textarea/Textarea';
import Checkbox from 'shared/Checkbox/Checkbox';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { useCreateProperty } from 'api/queries';
import { useNavigate } from 'react-router-dom';
import { EnumDays } from 'api/generated-api-client';

export interface AddPropertyProps {}

const AddProperty: FC<AddPropertyProps> = () => {
  const validationSchema = z.object({
    name: z.string({ required_error: 'Un nom est requis' }),
    description: z.string({ required_error: 'Une description est requise' }),
    adress: z.optional(z.string()),
    zipcode: z.string({ required_error: 'Un code postal est requis' }),
    city: z.string({ required_error: 'Une ville est requise' }),
    country: z.string({ required_error: 'Un pays est requis' }),
    size: z.string({ required_error: 'Une superficie est requise' }),
    price: z.string({ required_error: 'Un prix est requis' }),
    free_days: z.array(z.string()),
  });

  const { mutateAsync: createProperty } = useCreateProperty();

  const enumMapper = (dayString: string): EnumDays => {
    switch (dayString.toLowerCase()) {
      case 'lundi':
        return EnumDays.MONDAY;
      case 'mardi':
        return EnumDays.TUESDAY;
      case 'mercredi':
        return EnumDays.WEDNESDAY;
      case 'jeudi':
        return EnumDays.THURSDAY;
      case 'vendredi':
        return EnumDays.FRIDAY;
      case 'samedi':
        return EnumDays.SATURDAY;
      case 'dimanche':
        return EnumDays.SUNDAY;
      default:
        return EnumDays.SUNDAY; // Retourne null si la chaîne ne correspond à aucun jour de la semaine
    }
  };

  type FormData = z.infer<typeof validationSchema>;

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<FormData>({
    resolver: (values) => {
      try {
        validationSchema.parse(values);
        return { values, errors: {} };
      } catch (error: any) {
        return { values: {}, errors: error.flatten().fieldErrors };
      }
    },
  });

  const navigate = useNavigate();
  const { isLoading, data } = useCreateProperty();

  const handleCheckboxChange = (day: string, isChecked: boolean) => {
    const currentValues = getValues(); // Obtenir les valeurs actuelles du formulaire
    const updatedDays = isChecked
      ? [...currentValues.free_days, day] // Ajouter le jour au tableau
      : currentValues.free_days.filter((d) => d !== day); // Retirer le jour du tableau

    // Mettre à jour la valeur du champ "days" dans le formulaire
    setValue('free_days', updatedDays);
  };

  useEffect(() => {
    console.log('passeee');
    if (data) {
      navigate(`/add-property-amenities?id=${data}`);
    }
  }, [data]);

  return (
    <div
      className={`nc-PageAddListing1 px-4 max-w-3xl mx-auto pb-24 pt-14 sm:py-24 lg:pb-32`}
      data-nc-id="PageAddListing1"
    >
      <div className="space-y-11">
        <div>
          <span className="text-4xl font-semibold">
            {' '}
            Détails de l'espace Fleexoff
          </span>{' '}
        </div>

        {/* --------------------- */}
        {!isLoading && (
          <div className="listingSection__wrap ">
            <form
              onSubmit={handleSubmit(async (data) => {
                const property = await createProperty({
                  name: data.name,
                  street: data.adress || '',
                  zipcode: data.zipcode,
                  city: data.city,
                  country: data.country,
                  price_per_day: parseInt(data.price),
                  description: data.description,
                  size: parseInt(data.size),
                  fleexoff: false,
                  free_days: data.free_days.map((day: string) =>
                    enumMapper(day),
                  ),
                  amenities: [],
                });
                navigate(`/add-property-amenities?id=${property}`);
              })}
            >
              <div className="space-y-8">
                <FormItem label="Nom" id="name" errors={errors}>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <Input type="text" className="mt-1" {...field} />
                    )}
                  />
                </FormItem>

                <FormItem label="Description" id="description" errors={errors}>
                  <Controller
                    name="description"
                    control={control}
                    render={({ field }) => <Textarea rows={6} {...field} />}
                  />
                </FormItem>
              </div>
              <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
              <div className="space-y-8">
                <FormItem label="Adresse" id="adress" errors={errors}>
                  <Controller
                    name="adress"
                    control={control}
                    render={({ field }) => (
                      <Input type="text" className="mt-1" {...field} />
                    )}
                  />
                </FormItem>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-5">
                  <FormItem label="Ville" id="city" errors={errors}>
                    <Controller
                      name="city"
                      control={control}
                      render={({ field }) => (
                        <Input type="text" className="mt-1" {...field} />
                      )}
                    />
                  </FormItem>

                  <FormItem label="Code postal" id="zipcode" errors={errors}>
                    <Controller
                      name="zipcode"
                      control={control}
                      render={({ field }) => (
                        <Input type="text" className="mt-1" {...field} />
                      )}
                    />
                  </FormItem>

                  <FormItem label="Pays" id="country" errors={errors}>
                    <Controller
                      name="country"
                      control={control}
                      defaultValue="France"
                      render={({ field }) => (
                        <Input className="mt-1" {...field} disabled />
                      )}
                    />
                  </FormItem>
                </div>
                <div className="space-y-8">
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-5">
                    <FormItem label="Superficie" id="size" errors={errors}>
                      <Controller
                        name="size"
                        control={control}
                        render={({ field }) => (
                          <div className="relative">
                            <Input
                              type="number"
                              className="!pl-8 !pr-10"
                              placeholder="0"
                              {...field}
                            />
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                              <span className="text-gray-500">m²</span>
                            </div>
                          </div>
                        )}
                      />
                    </FormItem>

                    <FormItem
                      label="Prix à la journée"
                      id="price"
                      errors={errors}
                    >
                      <Controller
                        name="price"
                        control={control}
                        render={({ field }) => (
                          <div className="relative">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                              <span className="text-gray-500">€</span>
                            </div>
                            <Input
                              type="number"
                              className="!pl-8 !pr-10"
                              placeholder="0.00"
                              {...field}
                            />
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                              <span className="text-gray-500">EUR</span>
                            </div>
                          </div>
                        )}
                      />
                    </FormItem>

                    <FormItem label="Type de location">
                      <Select>
                        <option value="true">Fleexoff</option>
                        <option value="false">Bail sous seing privé</option>
                      </Select>
                    </FormItem>
                  </div>
                </div>
                <div className="space-y-8">
                  {/* ITEM */}
                  <div>
                    <label className="text-lg font-semibold" htmlFor="">
                      Jours disponibles
                    </label>
                    <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                      {[
                        'Lundi',
                        'Mardi',
                        'Mercredi',
                        'Jeudi',
                        'Vendredi',
                        'Samedi',
                        'Dimanche',
                      ].map((day) => (
                        <div
                          key={day}
                          className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5"
                        >
                          <Controller
                            name="free_days" // Utilisez le même nom pour tous les jours
                            control={control}
                            defaultValue={[
                              'Lundi',
                              'Mardi',
                              'Mercredi',
                              'Jeudi',
                              'Vendredi',
                              'Samedi',
                              'Dimanche',
                            ]} // Initialisez avec un tableau vide
                            render={({ field }) => (
                              <>
                                <Checkbox
                                  {...field}
                                  label={day}
                                  defaultChecked
                                  onChange={(e) => handleCheckboxChange(day, e)}
                                />
                              </>
                            )}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {/* --------------------- */}
                <div className="flex justify-end space-x-5">
                  <ButtonPrimary type="submit">
                    {'Ajouter les services et équipements'}
                  </ButtonPrimary>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddProperty;
