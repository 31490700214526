import React from "react";

const LogoSvg = () => {
  return (
    <svg
      id="Calque_1"
      data-name="Calque 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1080 1080"
      style={{ transform: "scale(1.7)" }}
    >
      <defs>
        <style></style>
        <linearGradient
          id="Dégradé_sans_nom_8"
          data-name="Dégradé sans nom 8"
          x1="535.75"
          y1="525.91"
          x2="674.65"
          y2="525.91"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".02" stopColor="#707bf7" />
          <stop offset=".41" stopColor="#83b1f5" />
          <stop offset=".8" stopColor="#95e4f4" />
          <stop offset="1" stopColor="#9cf8f4" />
        </linearGradient>
      </defs>
      <g>
        <path
          className="cls-1"
          d="M106.03,397.44c9.89,0,16.76,1.92,24.17,5.77v20.88c-5.77-3.02-11.54-4.94-19.23-4.94-14.29,0-22.53,6.32-22.53,21.43v17.86h40.39v21.43h-40.39v118.69h-23.63v-118.69h-25.55v-21.43h25.55v-19.51c0-26.37,14.01-41.49,41.21-41.49Zm75,3.3v197.81h-23.63v-197.81h23.63Z"
        />
        <path
          className="cls-1"
          d="M358.24,538.93h-120.61c4.67,24.45,24.73,41.21,51.93,41.21,20.61,0,38.19-9.89,47.26-22.8l16.21,14.01c-14.01,18.68-37.09,30.5-64.29,30.5-43.96,0-76.65-31.87-76.65-73.9s31.6-72.81,73.63-72.81,73.08,31.32,73.08,73.36c0,3.3-.27,7.14-.55,10.44Zm-120.89-21.43h96.44c-4.4-24.18-23.63-40.66-48.35-40.66s-43.68,16.76-48.08,40.66Z"
        />
        <path
          className="cls-1"
          d="M526.11,538.93h-120.61c4.67,24.45,24.73,41.21,51.92,41.21,20.61,0,38.19-9.89,47.26-22.8l16.21,14.01c-14.01,18.68-37.09,30.5-64.29,30.5-43.96,0-76.65-31.87-76.65-73.9s31.59-72.81,73.63-72.81,73.08,31.32,73.08,73.36c0,3.3-.27,7.14-.55,10.44Zm-120.89-21.43h96.44c-4.4-24.18-23.63-40.66-48.36-40.66s-43.68,16.76-48.08,40.66Z"
        />
        <path
          className="cls-1"
          d="M681.89,528.49c0-41.76,32.14-73.36,74.73-73.36s74.45,31.6,74.45,73.36-32.14,73.35-74.45,73.35-74.73-31.59-74.73-73.35Zm124.73,0c0-28.85-21.43-50.55-50-50.55s-50.28,21.7-50.28,50.55,21.43,50.55,50.28,50.55,50-21.7,50-50.55Z"
        />
        <path
          className="cls-1"
          d="M892.62,440.57v17.86h40.38v21.43h-40.38v118.69h-23.63v-118.69h-25.55v-21.43h25.55v-19.51c0-26.37,14.01-41.49,41.21-41.49,9.89,0,16.76,1.92,24.18,5.77v20.88c-5.77-3.02-11.54-4.94-19.23-4.94-14.29,0-22.53,6.32-22.53,21.43Z"
        />
        <path
          className="cls-1"
          d="M997.29,440.57v17.86h40.39v21.43h-40.39v118.69h-23.63v-118.69h-25.55v-21.43h25.55v-19.51c0-26.37,14.01-41.49,41.21-41.49,9.89,0,16.76,1.92,24.18,5.77v20.88c-5.77-3.02-11.54-4.94-19.23-4.94-14.29,0-22.53,6.32-22.53,21.43Z"
        />
      </g>
      <path
        className="cls-2"
        style={{ fill: "url(#Dégradé_sans_nom_8)" }}
        d="M624.15,529.45l40.69,.04v-7.17h-41.09l50.1-65.59h-137.27l50.1,65.59h-41.09v7.17h40.66l-50.48,65.59h138.9l-50.51-65.63Zm-39.53-65.53l41.13-.04h14.19s-34.74,46.02-34.74,46.02l-34.72-45.98h14.14Zm41.51,123.98l-41.78,.04h-14.72s35.29-46.84,35.29-46.84l35.51,46.8h-14.31Z"
      />
    </svg>
  );
};

export default LogoSvg;
