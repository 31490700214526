import { FC } from 'react';
import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism';

export interface PageCGUProps {
  className?: string;
}

const PageCGU: FC<PageCGUProps> = ({ className = '' }) => {
  return (
    <div
      className="container py-16 lg:py-28 space-y-16 lg:space-y-18"
      data-nc-id="PageCGU"
    >
      <BgGlassmorphism />

      <div className="w-screen max-w-full xl:max-w-lg space-y-5 lg:space-y-7">
        <h2 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100">
          Conditions générales d'utilisation
        </h2>
        <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
          En vertu de l’article 6 de la loi n° 2004-575 du 21 juin 2004 pour la
          confiance dans l’économie numérique, il est précisé aux utilisateurs
          du site www.fleexoff.com l’identité des différents intervenants dans
          le cadre de sa réalisation et de son suivi.
        </span>
      </div>

      <div className="nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between mb-12 lg:mb-16 text-neutral-900 dark:text-neutral-50">
        <div className="max-w-2xl">
          <h2 className="text-3xl md:text-4xl font-semibold">
            À propos de ACACIA SAS
          </h2>
          <span className="mt-2 md:mt-3 font-normal block text-base sm:text-xl text-neutral-500 dark:text-neutral-400">
            <p>
              ACACIA SAS est une société par actions simplifiée au capital de 1
              000 € immatriculée au R.C.S de Lisieux sous le numéro 929 442 549.
              Son siège social se situe au 550 CHEMIN DE L'EPINE PIEU, 14340
              AUVILLARS.
            </p>
            <p>Vous pouvez nous contacter à l'adresse contact@fleexoff.com</p>
          </span>
        </div>
      </div>

      <div className="nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between mb-12 lg:mb-16 text-neutral-900 dark:text-neutral-50">
        <div className="max-w-2xl">
          <h2 className="text-3xl md:text-4xl font-semibold">
            Au sujet du site
          </h2>
          <span className="mt-2 md:mt-3 font-normal block text-base sm:text-xl text-neutral-500 dark:text-neutral-400">
            <p>
              Le site www.fleexoff.com est conçu et édité par la SAS ACACIA.
            </p>
          </span>
        </div>
      </div>

      <div className="nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between mb-12 lg:mb-16 text-neutral-900 dark:text-neutral-50">
        <div className="max-w-2xl">
          <h2 className="text-3xl md:text-4xl font-semibold">
            Responsable de publication
          </h2>
          <span className="mt-2 md:mt-3 font-normal block text-base sm:text-xl text-neutral-500 dark:text-neutral-400">
            <p>Responsable de publication : Victor LAINE</p>
          </span>
        </div>
      </div>

      <div className="nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between mb-12 lg:mb-16 text-neutral-900 dark:text-neutral-50">
        <div className="max-w-2xl">
          <h2 className="text-3xl md:text-4xl font-semibold">Hébergement</h2>
          <span className="mt-2 md:mt-3 font-normal block text-base sm:text-xl text-neutral-500 dark:text-neutral-400">
            <p>Hébergement : Amazon Web Services</p>
            <p>Siège social : Seattle, Washington, États-Unis</p>
          </span>
        </div>
      </div>

      <div className="nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between mb-12 lg:mb-16 text-neutral-900 dark:text-neutral-50">
        <div className="max-w-2xl">
          <h2 className="text-3xl md:text-4xl font-semibold">
            Conditions d’utilisation
          </h2>
          <span className="mt-2 md:mt-3 font-normal block text-base sm:text-xl text-neutral-500 dark:text-neutral-400">
            <p>
              L’utilisation du présent site implique l’acceptation pleine et
              entière des conditions générales d’utilisation décrites ci-après.
              Ces conditions d’utilisation sont susceptibles d’être modifiées ou
              complétées à tout moment.
            </p>
          </span>
        </div>
      </div>

      <div className="nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between mb-12 lg:mb-16 text-neutral-900 dark:text-neutral-50">
        <div className="max-w-2xl">
          <h2 className="text-3xl md:text-4xl font-semibold">Informations</h2>
          <span className="mt-2 md:mt-3 font-normal block text-base sm:text-xl text-neutral-500 dark:text-neutral-400">
            <p>
              Les informations et documents du site sont présentés à titre
              indicatif, ne revêtent pas un caractère exhaustif, et ne peuvent
              engager la responsabilité du propriétaire du site.
            </p>
            <p>
              Le propriétaire du site ne peut être tenu responsable des dommages
              directs et indirects consécutifs à l’accès au site.
            </p>
          </span>
        </div>
      </div>

      <div className="nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between mb-12 lg:mb-16 text-neutral-900 dark:text-neutral-50">
        <div className="max-w-2xl">
          <h2 className="text-3xl md:text-4xl font-semibold">Interactivité</h2>
          <span className="mt-2 md:mt-3 font-normal block text-base sm:text-xl text-neutral-500 dark:text-neutral-400">
            <p>
              Les utilisateurs du site peuvent éventuellement y déposer du
              contenu, apparaissant sur le site dans des espaces dédiés. Le
              contenu déposé reste sous la responsabilité de leurs auteurs, qui
              en assument pleinement l’entière responsabilité juridique.
            </p>
            <p>
              Le propriétaire du site se réserve le droit de retirer sans
              préavis et sans justification tout contenu déposé par les
              utilisateurs qui ne satisferait pas à la charte déontologique du
              site ou à la législation en vigueur.
            </p>
          </span>
        </div>
      </div>

      <div className="nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between mb-12 lg:mb-16 text-neutral-900 dark:text-neutral-50">
        <div className="max-w-2xl">
          <h2 className="text-3xl md:text-4xl font-semibold">
            Propriété intellectuelle
          </h2>
          <span className="mt-2 md:mt-3 font-normal block text-base sm:text-xl text-neutral-500 dark:text-neutral-400">
            <p>
              Sauf mention contraire, tous les éléments accessibles sur le site
              (textes, images, graphismes, logo, icônes, sons, logiciels, etc.)
              restent la propriété exclusive de leurs auteurs, en ce qui
              concerne les droits de propriété intellectuelle ou les droits
              d’usage.
            </p>
            <p>
              Toute reproduction, représentation, modification, publication,
              adaptation de tout ou partie des éléments du site, quel que soit
              le moyen ou le procédé utilisé, est interdite, sauf autorisation
              écrite préalable de l’auteur.
            </p>
            <p>
              Toute exploitation non autorisée du site ou de l’un quelconque des
              éléments qu’il contient est considérée comme constitutive d’une
              contrefaçon et peut être poursuivie en justice.
            </p>
          </span>
        </div>
      </div>

      <div className="nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between mb-12 lg:mb-16 text-neutral-900 dark:text-neutral-50">
        <div className="max-w-2xl">
          <h2 className="text-3xl md:text-4xl font-semibold">Liens</h2>
          <span className="mt-2 md:mt-3 font-normal block text-base sm:text-xl text-neutral-500 dark:text-neutral-400">
            <h3 className="text-2xl md:text-3xl font-semibold">
              Liens sortants
            </h3>
            <p>
              Le propriétaire du site décline toute responsabilité et n’est pas
              engagé par le référencement via des liens hypertextes, de
              ressources tierces présentes sur le réseau Internet, tant en ce
              qui concerne leur contenu que leur pertinence.
            </p>
            <h3 className="text-2xl md:text-3xl font-semibold">
              Liens entrants
            </h3>
            <p>
              Le propriétaire du site autorise les liens hypertextes vers l’une
              des pages de ce site, à condition que ceux-ci ouvrent une nouvelle
              fenêtre et soient présentés de manière non équivoque afin d’éviter
              :
            </p>
            <ul className="list-disc pl-5">
              <li>
                Tout risque de confusion entre le site citant et le propriétaire
                du site
              </li>
              <li>
                Ainsi que toute présentation tendancieuse, ou contraire aux lois
                en vigueur.
              </li>
            </ul>
            <p>
              Le propriétaire du site se réserve le droit de demander la
              suppression d’un lien s’il estime que le site source ne respecte
              pas les règles ainsi définies.
            </p>
          </span>
        </div>
      </div>

      <div className="nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between mb-12 lg:mb-16 text-neutral-900 dark:text-neutral-50">
        <div className="max-w-2xl">
          <h2 className="text-3xl md:text-4xl font-semibold">
            Confidentialité
          </h2>
          <span className="mt-2 md:mt-3 font-normal block text-base sm:text-xl text-neutral-500 dark:text-neutral-400">
            <p>
              Tout utilisateur dispose d’un droit d’accès, de rectification et
              d’opposition aux données personnelles le concernant, en effectuant
              sa demande écrite et signée, accompagnée d’une preuve d’identité.
            </p>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageCGU;
