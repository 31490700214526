import { Fragment, FC } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { CalendarIcon } from '@heroicons/react/24/outline';
import DatePicker, { registerLocale } from 'react-datepicker';
import ClearDataButton from 'components/HeroSearchForm/ClearDataButton';
import fr from 'date-fns/locale/fr';
import DatePickerCustomHeaderTwoMonth from 'components/DatePickerCustomHeaderTwoMonth';
import DatePickerCustomDay from 'components/DatePickerCustomDay';
registerLocale('fr-FR', fr);

export interface StayDatesRangeInputProps {
  className?: string;
  availableDates: Date[] | null;
  onChangeSelectedDate: (date: Date | null) => void;
  selectedDate?: Date | null;
  free_days:
    | (
        | 'MONDAY'
        | 'TUESDAY'
        | 'WEDNESDAY'
        | 'THURSDAY'
        | 'FRIDAY'
        | 'SATURDAY'
        | 'SUNDAY'
      )[]
    | null
    | undefined;
}

const StayDatesRangeInput: FC<StayDatesRangeInputProps> = ({
  className = 'flex-1',
  availableDates,
  onChangeSelectedDate,
  selectedDate,
  free_days,
}) => {
  const today = new Date(Date.now());

  const renderInput = (selectDateChoosen: Date | null) => {
    return (
      <>
        <div className="text-neutral-300 dark:text-neutral-400">
          <CalendarIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow text-left">
          <span className="block xl:text-lg font-semibold">
            {selectDateChoosen?.toLocaleDateString('fr-FR', {
              month: 'short',
              day: '2-digit',
            }) || 'Add dates'}
          </span>
          <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
            {'Date'}
          </span>
        </div>
      </>
    );
  };

  const handleChange = (date: Date | null) => {
    onChangeSelectedDate(date);
  };

  const isFreeday = (date: Date) => {
    const ordonedDays: (
      | 'MONDAY'
      | 'TUESDAY'
      | 'WEDNESDAY'
      | 'THURSDAY'
      | 'FRIDAY'
      | 'SATURDAY'
      | 'SUNDAY'
    )[] = [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY',
      'SUNDAY',
    ];
    if (free_days) {
      const day = date.getDay();
      const textDay = ordonedDays[day];

      return free_days.includes(textDay);
    }
    return false;
  };

  return (
    <Popover className={`StayDatesRangeInput z-10 relative flex ${className}`}>
      {({ open }) => (
        <>
          <Popover.Button
            className={`flex-1 flex relative p-3 items-center space-x-3 focus:outline-none ${
              open ? 'shadow-lg' : ''
            }`}
          >
            {renderInput(selectedDate as Date)}
            {selectedDate && open && (
              <ClearDataButton onClick={() => onChangeSelectedDate(null)} />
            )}
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-auto xl:-right-10 right-0 z-10 mt-3 top-full w-screen max-w-sm px-4 sm:px-0 lg:max-w-3xl">
              <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-neutral-800 p-8">
                <DatePicker
                  excludeDates={[new Date()]}
                  includeDates={availableDates ? availableDates : undefined}
                  selected={selectedDate}
                  onChange={handleChange}
                  startDate={availableDates ? availableDates[0] : undefined}
                  monthsShown={2}
                  showPopperArrow={false}
                  minDate={today}
                  inline
                  shouldCloseOnSelect={true}
                  calendarStartDay={1}
                  locale="fr-FR"
                  renderCustomHeader={(p) => (
                    <DatePickerCustomHeaderTwoMonth {...p} />
                  )}
                  renderDayContents={(day, date) => (
                    <DatePickerCustomDay dayOfMonth={day} date={date} />
                  )}
                  filterDate={
                    availableDates ? undefined : (date) => isFreeday(date)
                  }
                />
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default StayDatesRangeInput;
