import { FC, useEffect } from 'react';
import Input from 'shared/Input/Input';
import { Link, useNavigate } from 'react-router-dom';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import {
  useLoginUserByEmail,
  useRegisterOrLoginUserByGoogle,
} from 'api/queries';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import toast from 'react-hot-toast';
import Cookies from 'js-cookie';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import ReactGA from 'react-ga4';

export interface LoginProps {
  className?: string;
}

interface IFormInput {
  email: string;
  password: string;
}

const Login: FC<LoginProps> = ({ className = '' }) => {
  const {
    mutate: loginUserByEmail,
    isLoading,
    isSuccess,
    isError,
  } = useLoginUserByEmail();

  const {
    mutateAsync: registerOrLoginUserByGoogle,
    isSuccess: isSuccessGoogle,
  } = useRegisterOrLoginUserByGoogle();

  const { register, handleSubmit } = useForm<IFormInput>();

  const navigate = useNavigate();

  useEffect(() => {
    ReactGA.event({
      category: 'User event',
      action: 'Email Login',
    });
  }, [isSuccess]);

  useEffect(() => {
    if (isSuccess || isSuccessGoogle) {
      navigate(0);
    }
  }, [isSuccess, isSuccessGoogle]);

  useEffect(() => {
    if (isError) {
      toast.error('Email ou mot de passe incorrect');
    }
  }, [isError]);

  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      navigate('/');
    }
  }, []);

  const responseMessage = (response: CredentialResponse) => {
    ReactGA.event({
      category: 'User event',
      action: 'Google Login',
    });
    registerOrLoginUserByGoogle({ token: response.credential || '' });
  };
  const errorMessage = () => {};

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-10 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          <FormattedMessage id="login.title" />
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">
          <form
            aria-label="login form"
            className="grid grid-cols-1 gap-6"
            onSubmit={handleSubmit((data) => {
              loginUserByEmail({
                email: data.email,
                password: data.password,
              });
            })}
          >
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                <FormattedMessage id="login.email" />
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1"
                {...register('email', { required: true })}
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                <FormattedMessage id="login.password" />
                <Link to="/forgot-password" className="text-sm">
                  <FormattedMessage id="login.forgot" />
                </Link>
              </span>
              <Input
                type="password"
                className="mt-1"
                {...register('password', { required: true })}
                data-testid="password-input"
              />
            </label>

            {!isSuccess && !isLoading && (
              <ButtonPrimary type="submit">
                <FormattedMessage id="login.submit" />
              </ButtonPrimary>
            )}
          </form>
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              <FormattedMessage id="login.or" />
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          <div className="grid gap-3 flex content-center justify-center">
            <GoogleLogin
              onSuccess={responseMessage}
              onError={errorMessage}
              size="large"
              shape="pill"
              logo_alignment="center"
              type="icon"
              text="signup_with"
            />
          </div>
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            <FormattedMessage id="login.no-account" />{' '}
            <Link to="/signup">
              <u>
                <FormattedMessage id="login.signup" />
              </u>
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Login;
