import Heading from 'components/Heading/Heading';
import { FC } from 'react';
import VectorImg from 'images/VectorHIW.svg';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import NcPicture from 'shared/NcImage/NcPicture';

export interface SectionHowItWorkProps {
  className?: string;
}

const SectionHowItWork: FC<SectionHowItWorkProps> = ({ className = '' }) => {
  const navigate = useNavigate();
  const intl = useIntl();

  return (
    <div
      className={`nc-SectionHowItWork  ${className}`}
      data-nc-id="SectionHowItWork"
    >
      <Heading isCenter desc="Louer un espace en quelques minutes">
        Comment ça fonctionne ?
      </Heading>
      <div className="mt-20 relative grid md:grid-cols-3 gap-20">
        <img
          className="hidden md:block absolute inset-x-0 top-10"
          src={VectorImg}
          alt=""
        />
        <div className="relative flex flex-col items-center max-w-xs mx-auto">
          <NcPicture
            srcPng="https://d10qdsce7xisc3.cloudfront.net/static/search.png"
            srcWebp="https://d10qdsce7xisc3.cloudfront.net/static/search.webp"
            srcAvif="https://d10qdsce7xisc3.cloudfront.net/static/search.avif"
            alt={intl.formatMessage({ id: 'home.splash.img.alt' })}
            className="w-full"
          />
          <div className="text-center mt-auto">
            <h3 className="text-xl font-semibold">{`Je définis mes critères`}</h3>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              {`Date, durée, type d'espace, nombre de personnes, mobilier, équipements...`}
            </span>
          </div>
        </div>
        <div className="relative flex flex-col items-center max-w-xs mx-auto">
          <NcPicture
            srcPng="https://d10qdsce7xisc3.cloudfront.net/static/date.png"
            srcWebp="https://d10qdsce7xisc3.cloudfront.net/static/date.webp"
            srcAvif="https://d10qdsce7xisc3.cloudfront.net/static/date.avif"
            alt={intl.formatMessage({ id: 'home.splash.img.alt' })}
            className="w-full"
          />
          <div className="text-center mt-auto">
            <h3 className="text-xl font-semibold">{`Je réserve mon espace`}</h3>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              {`Je choisis la date et je réserve en quelques clics, sans engagement`}
            </span>
          </div>
        </div>
        <div className="relative flex flex-col items-center max-w-xs mx-auto">
          <NcPicture
            srcPng="https://d10qdsce7xisc3.cloudfront.net/static/BecomeAnAuthorImg.png"
            srcWebp="https://d10qdsce7xisc3.cloudfront.net/static/BecomeAnAuthorImg.webp"
            srcAvif="https://d10qdsce7xisc3.cloudfront.net/static/BecomeAnAuthorImg.avif"
            alt={intl.formatMessage({ id: 'home.splash.img.alt' })}
            className="w-full"
          />
          <div className="text-center mt-auto">
            <h3 className="text-xl font-semibold">{`Je profite de mon espace`}</h3>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              {`J'accède à mon espace en autonomie et je peux commencer à travailler sans contrainte`}
            </span>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center mt-10">
        <ButtonPrimary
          onClick={() => {
            navigate('/listing-stay');
          }}
        >
          <FormattedMessage id="home.splash.button.label" />
        </ButtonPrimary>
      </div>
    </div>
  );
};

export default SectionHowItWork;
