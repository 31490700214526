import React from 'react';
import HeroSearchForm2Mobile from './HeroSearchForm2Mobile';
import HeroSearchForm2RealEstateMobile from './HeroSearchForm2RealEstateMobile';
import { useLocation } from 'react-router-dom';

const PAGES_REAL_ESTATE: string[] = [
  '/home-2',
  '/listing-real-estate',
  '/listing-real-estate-map',
];

const HeroSearchForm2MobileFactory = () => {
  const pathname = useLocation().pathname;
  if (PAGES_REAL_ESTATE.includes(pathname as string)) {
    return <HeroSearchForm2RealEstateMobile />;
  }
  return <HeroSearchForm2Mobile />;
};

export default HeroSearchForm2MobileFactory;
