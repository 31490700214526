import React, { FC, useEffect, useState } from 'react';
import LocationInput from '../LocationInput';
import { Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { Fragment } from 'react';
import useOutsideAlerter from 'hooks/useOutsideAlerter';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Checkbox from 'shared/Checkbox/Checkbox';

export interface SearchFormProps {}

interface IFormInput {
  address?: string;
  distance?: number;
  filters: {
    days: string[];
  };
}

const SearchForm: FC<SearchFormProps> = () => {
  const [dates, setDates] = useState<string[]>([
    'Lundi',
    'Mardi',
    'Mercredi',
    'Jeudi',
    'Vendredi',
    'Samedi',
    'Dimanche',
  ]);

  const { register, handleSubmit, watch, setValue } = useForm<IFormInput>({
    defaultValues: {
      address: '',
      distance: 10,
      filters: {
        days: dates,
      },
    },
  });

  const navigate = useNavigate();

  const dropOffLocationType = 'roundTrip';

  //
  const refGuestContainer = React.createRef<HTMLDivElement>();
  const [openGuest, setOpenGuest] = useState(false);
  useOutsideAlerter(refGuestContainer, () => setOpenGuest(false));
  useEffect(() => {}, [dates]);

  const updateDates = (date: string, checked: boolean) => {
    if (checked) {
      setDates([...dates, date]);
    } else {
      setDates(dates.filter((item) => item !== date));
    }
  };

  const renderDayPicker = () => {
    return (
      <div className="">
        <div ref={refGuestContainer} className="relative">
          <>
            <div
              className={`cursor-pointer px-4 py-1.5 rounded-md inline-flex items-center font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-0 text-xs`}
              onClick={() => setOpenGuest(!openGuest)}
            >
              <span>Filtres</span>
              <ChevronDownIcon
                className={`${
                  openGuest ? '' : 'text-opacity-70'
                } ml-2 h-4 w-4 group-hover:text-opacity-80 transition ease-in-out duration-150`}
                aria-hidden="true"
              />
            </div>
            <Transition
              show={openGuest}
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <div className="absolute z-20 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 left-1/2 -translate-x-1/2  py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl ring-1 ring-black/5 dark:ring-white/10">
                <>
                  <div>
                    <label className="text-lg font-semibold" htmlFor="">
                      Jours disponibles
                    </label>
                    <div className="mt-6 grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-2 gap-5">
                      <Checkbox
                        label="Lundi"
                        name="Lundi"
                        defaultChecked={dates.includes('Lundi')}
                        onChange={(checked) => updateDates('Lundi', checked)}
                      />
                      <Checkbox
                        label="Mardi"
                        name="Mardi"
                        defaultChecked={dates.includes('Mardi')}
                        onChange={(checked) => updateDates('Mardi', checked)}
                      />
                      <Checkbox
                        label="Mercredi"
                        name="Mercredi"
                        defaultChecked={dates.includes('Mercredi')}
                        onChange={(checked) => updateDates('Mercredi', checked)}
                      />
                      <Checkbox
                        label="Jeudi"
                        name="Jeudi"
                        defaultChecked={dates.includes('Jeudi')}
                        onChange={(checked) => updateDates('Jeudi', checked)}
                      />
                      <Checkbox
                        label="Vendredi"
                        name="Vendredi"
                        defaultChecked={dates.includes('Vendredi')}
                        onChange={(checked) => updateDates('Vendredi', checked)}
                      />
                      <Checkbox
                        label="Samedi"
                        name="Samedi"
                        defaultChecked={dates.includes('Samedi')}
                        onChange={(checked) => updateDates('Samedi', checked)}
                      />
                      <Checkbox
                        label="Dimanche"
                        name="Dimanche"
                        defaultChecked={dates.includes('Dimanche')}
                        onChange={(checked) => updateDates('Dimanche', checked)}
                      />
                    </div>
                  </div>
                </>
              </div>
            </Transition>
          </>
        </div>
      </div>
    );
  };

  const renderRadioBtn = () => {
    return (
      <div className="pb-3 flex justify-center space-x-3">
        <div
          className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer select-none ${
            dropOffLocationType === 'roundTrip'
              ? 'border border-neutral-300 dark:border-neutral-700'
              : 'border border-neutral-300 dark:border-neutral-700'
          }`}
        >
          {renderDayPicker()}
        </div>
      </div>
    );
  };

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    const encodedAddress = encodeURIComponent(data.address || '');
    const url = `/listing-stay-map?q=${encodedAddress}&distance=10${
      dates.length > 0
        ? dates.length >= 7
          ? ''
          : `&days=${dates.join(',')}`
        : ''
    }`;
    data.distance ? url.concat(`&distance=10`) : url.concat(`&distance=10`);

    navigate(url);
  };

  const renderForm = () => {
    return (
      <form onSubmit={handleSubmit(onSubmit)} className="w-full relative ">
        {renderRadioBtn()}
        <div className="flex justify-center space-x-3">
          <div className="flex w-3/4 rounded-full border border-neutral-200 dark:border-neutral-700 bg-white dark:bg-neutral-800">
            <LocationInput
              register={register}
              handleChange={setValue}
              placeHolder="Localisation"
              desc="Chercher une ville, une adresse ..."
              className="flex-1"
              onClick={() => {}}
              value={watch('address')}
            />
          </div>
        </div>
      </form>
    );
  };

  return renderForm();
};

export default SearchForm;
