import { useContext, useEffect, useState } from 'react';

import { AuthContext } from 'context/AuthContext';
import StayCard from 'components/StayCard/StayCard';
import { useUpdateUser } from 'api/queries';

const AccountSavelists = () => {
  const userCtx = useContext(AuthContext);
  const user = userCtx?.user;
  const data = userCtx.user?.bookmarks;
  const [currentBookmarks, setCurrentBookmarks]: any = useState();

  useEffect(() => {
    console.log('INto use effect');
    if (userCtx.user) {
      console.log(userCtx.user?.bookmarks);
      setCurrentBookmarks(userCtx.user?.bookmarks);
      userCtx.fetchUser();
    }
  }, [userCtx.user]);

  const { mutate: updateUser } = useUpdateUser({
    onSuccess: (response) => {
      setCurrentBookmarks(response?.bookmarks);
    },
  });
  const onBookmarkClick = async (id: string) => {
    let newBookmarks: any;

    if (user?.id) {
      if (currentBookmarks.map((b: any) => b.id).includes(id)) {
        newBookmarks = currentBookmarks?.filter((b: any) => b.id !== id);
      } else {
        newBookmarks = currentBookmarks && [
          ...currentBookmarks,
          data?.find((e) => e.id === id),
        ];
      }

      updateUser({
        id: parseInt(user?.id),
        userUpdate: { bookmarks: newBookmarks },
      });
    }
  };

  console.log('passe');
  return (
    <div
      className={`nc-ListingStayPage relative overflow-hidden pb-24 lg:pb-28`}
      data-nc-id="ListingStayPage"
    >
      {/* <div className="mb-8 lg:mb-11">
      <TabFilters />
</div>*/}
      <div className="container relative overflow-hidden mt-5">
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          Mes favoris
        </h2>
        <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-5">
          {data &&
            data.length > 0 &&
            data?.map((stay) => (
              <StayCard
                key={stay.id}
                isPropertyBookmarked={currentBookmarks
                  ?.map((b: any) => b.id)
                  .includes(stay?.id)}
                data={stay}
                onBookmarkClick={onBookmarkClick}
              />
            ))}
        </div>
      </div>
      {/* <div className="flex mt-16 justify-center items-center">
      <Pagination pageCnt={7} currentPage={2} />
      </div> */}
    </div>
  );
};

export default AccountSavelists;
