import { ReactNode } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import ListingImageGallery from 'components/ListingImageGallery/ListingImageGallery';
import MobileFooterSticky from './components/MobileFooterSticky';
import { useGetProperty } from 'api/queries';

const DetailPagetLayout = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const thisPathname = useLocation().pathname;
  const modal = thisPathname?.includes('/listing-stay-detail/gallery');
  const params = new URLSearchParams(search);
  const id = params.get('id')!;
  const currentProperty = useGetProperty(params.get('id')!).data;

  const currentPropertyImages = currentProperty?.assets?.map(
    (asset, index) => ({
      id: index,
      url: `https://d10qdsce7xisc3.cloudfront.net/${id}/${asset}`,
    }),
  );

  const handleCloseModalImageGallery = () => {
    let params = new URLSearchParams(document.location.search);
    params.delete('modal');
    navigate(`/listing-stay-detail?id=${id}`);
  };

  const getImageGalleryListing = () => {
    if (thisPathname?.includes('/listing-stay-detail/gallery')) {
      return currentPropertyImages ? currentPropertyImages : [];
    }
    return [];
  };

  return (
    <div className="ListingDetailPage">
      <ListingImageGallery
        isShowModal={modal}
        onClose={handleCloseModalImageGallery}
        images={getImageGalleryListing()}
      />

      <div className="container ListingDetailPage__content">{children}</div>

      {/* STICKY FOOTER MOBILE */}
      <MobileFooterSticky />
    </div>
  );
};

export default DetailPagetLayout;
