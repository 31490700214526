import {
  FC,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import Badge from 'shared/Badge/Badge';
import LikeSaveBtns from 'components/LikeSaveBtns';
import StayDatesRangeInput from './StayDatesRangeInput';
import { useLocation, useNavigate } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import ButtonClose from 'shared/ButtonClose/ButtonClose';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import DetailPagetLayout from '../Layout';
import GuestsInput from './GuestsInput';
import {
  useGetProperty,
  useGetMonthlyDaysWithAvailabilityForProperty,
  useGetDailyAvailabilityForProperty,
  useUpdateUser,
} from 'api/queries';
import { AmenityDto } from 'api/generated-api-client';
import { FormattedMessage, useIntl } from 'react-intl';
import { AuthContext } from 'context/AuthContext';
import ReactGA from 'react-ga4';

const days = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY',
];
const StayDetailPageContainer: FC<{}> = () => {
  const { formatMessage } = useIntl();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const location = useLocation();
  const router = useNavigate();

  let [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);

  function closeModalAmenities() {
    setIsOpenModalAmenities(false);
  }

  function openModalAmenities() {
    setIsOpenModalAmenities(true);
  }

  const handleOpenModalImageGallery = () => {
    router(`${location.pathname}/gallery${location.search}`);
  };

  const onChangeSelectedDate = useCallback((date: Date | null) => {
    const start = date || new Date();
    setSelectedDate(start);
  }, []);

  /////

  //////

  /****
   * States
   * ****/

  const [availablesDates, setAvailablesDates] = useState<Date[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  /****
   * Hooks to fetch data
   * ****/

  /* Fetch details of current property */
  const currentProperty = useGetProperty(params.get('id')!);

  /* Fetch all dates with availabilities for given months */
  const daysWithAvailibities = useGetMonthlyDaysWithAvailabilityForProperty(
    currentProperty?.data?.id!,
    9,
  );

  const slots = useGetDailyAvailabilityForProperty(
    currentProperty?.data?.id!,
    selectedDate,
  );

  useEffect(() => {}, [slots]);
  /****
   * useEffects
   * *****/

  /* If no date is selected, select the first available date */
  useEffect(() => {
    if (selectedDate === null) {
      onChangeSelectedDate(availablesDates[0]);
    }
  }, [availablesDates]);

  useEffect(() => {
    if (
      daysWithAvailibities &&
      daysWithAvailibities.data &&
      daysWithAvailibities.data.availableDays &&
      daysWithAvailibities.data?.availableDays.length > 0
    ) {
      const dates: Date[] = daysWithAvailibities.data?.availableDays.map(
        (day) => {
          return new Date(day.day);
        },
      );

      if (JSON.stringify(dates) !== JSON.stringify(availablesDates)) {
        setAvailablesDates(dates);
      }
    }
  }, [daysWithAvailibities]);

  const { mutateAsync: updateUser } = useUpdateUser();
  const userCtx = useContext(AuthContext);
  const [currentBookmarks, setCurrentBookmarks]: any = useState();
  const user = userCtx?.user;

  useEffect(() => {
    if (user && currentProperty.data?.id) {
      userCtx.fetchUser();
      setCurrentBookmarks(user?.bookmarks);
    }
  }, [user, currentProperty.data?.id]);
  const renderSection1 = (
    name?: string | null,
    city?: string | null,
    country?: string | null,
    size?: number | null,
    fleexoff?: boolean | null,
    users?: any[],
  ) => {
    const onBookmarkProperty = () => {
      let newBookmarks;
      if (user?.id) {
        if (
          currentBookmarks
            .map((b: any) => b.id)
            .includes(currentProperty?.data?.id)
        ) {
          newBookmarks = currentBookmarks?.filter(
            (b: any) => b.id !== currentProperty?.data?.id,
          );
        } else {
          newBookmarks = currentBookmarks && [
            ...currentBookmarks,
            currentProperty.data,
          ];
        }
        updateUser({
          id: parseInt(user?.id),
          userUpdate: { bookmarks: newBookmarks },
        }).then((r) => {
          setCurrentBookmarks(r.bookmarks);
        });
      }
    };
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        <div className="flex justify-between items-center">
          <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
            {name}
          </h2>
          <LikeSaveBtns
            onLikeButtonClick={onBookmarkProperty}
            isPropertyBookmarked={currentBookmarks
              ?.map((b: any) => b.id)
              .includes(currentProperty.data?.id)}
          />
        </div>

        {/* 2 */}

        {/* 3 */}
        <div className="flex items-center space-x-4">
          {fleexoff && (
            <>
              <Badge name="Fleexoff" color="green" />{' '}
              <span className="mr-1">·</span>
            </>
          )}
          <span>{size}m2</span>
          <span>·</span>
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1">
              {' '}
              {city}, {country}
            </span>
          </span>
          <span>·</span>
          <span className="flex text-base text-center">
            {users?.length}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginTop: '3px' }}
              className="h-4 w-4"
              fill={'none'}
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
              />
            </svg>
          </span>
        </div>

        {/* 
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />5 */}

        {/* 6 */}
        {/* <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex items-center space-x-3 ">
            <i className=" las la-chair text-2xl "></i>
            <span className="">
              <span className="hidden sm:inline-block">Espace d'attente</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-key text-2xl"></i>
            <span className=" ">
              <span className="hidden sm:inline-block">Serrure connectée</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-wheelchair text-2xl"></i>
            <span className=" ">
              <span className="hidden sm:inline-block">Acces PMR</span>
            </span>
          </div>
        </div> */}
      </div>
    );
  };

  const renderSection2 = (description?: string | null) => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Description</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          {description}
        </div>
      </div>
    );
  };

  const renderSection3 = (amenities: AmenityDto[] | null | undefined) => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Equipements </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {` Cet espace comprend les équipements suivants :`}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}

        {amenities && amenities.length > 0 && (
          <>
            <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
              {amenities
                .filter((_, i) => i < 6)
                .map((amenity) => (
                  <div
                    key={amenity.label}
                    className="flex items-center space-x-3"
                  >
                    <i className={`text-3xl las ${amenity.icon}`}></i>
                    <span className=" ">
                      <FormattedMessage
                        id={`amenities.${amenity.label}`}
                      ></FormattedMessage>
                    </span>
                  </div>
                ))}
            </div>

            <div className="w-14 border-b border-neutral-200"></div>
            <div>
              <ButtonSecondary onClick={openModalAmenities}>
                Voir l'ensemble des équipements
              </ButtonSecondary>
            </div>
            {renderMotalAmenities(amenities)}
          </>
        )}
      </div>
    );
  };

  const renderMotalAmenities = (amenities: AmenityDto[]) => {
    return (
      <Transition appear show={isOpenModalAmenities} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalAmenities}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full max-w-4xl">
                <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Equipements
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalAmenities} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    {amenities
                      .filter((_, i) => i < 1212)
                      .map((amenity) => (
                        <div
                          key={amenity.label}
                          className="flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                        >
                          <i
                            className={`text-4xl text-neutral-6000 las ${amenity.icon}`}
                          ></i>
                          <span>
                            {' '}
                            <FormattedMessage
                              id={`amenities.${amenity.label}`}
                            ></FormattedMessage>
                          </span>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderSection4 = (
    price_per_day: number | null | undefined,
    free_days:
      | (
          | 'MONDAY'
          | 'TUESDAY'
          | 'WEDNESDAY'
          | 'THURSDAY'
          | 'FRIDAY'
          | 'SATURDAY'
          | 'SUNDAY'
        )[]
      | null
      | undefined,
  ) => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Tarifications</h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
        <div className="flow-root">
          <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4">
            {!price_per_day ? (
              <>
                <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
                  <span>Demi-journée · 7h - 12h30 ou 13h - 18h30</span>
                  <span>49€</span>
                </div>
                <div className="p-4  flex justify-between items-center space-x-4 rounded-lg">
                  <span>Journée · 7h - 18h30</span>
                  <span>79€</span>
                </div>
                <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
                  <span>Soirée · 19h30 - 23</span>
                  <span>39€</span>
                </div>
                <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
                  <span>Week-end · samedi 7h - dimanche 18h30</span>
                  <span>189€</span>
                </div>
              </>
            ) : (
              <>
                <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
                  <span>Tarifs à la journée : </span>
                  <span>{price_per_day ?? ''}€</span>
                </div>
                <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
                  <span>Jours disponibles :</span>

                  <span>
                    {/* {free_days?.map((day) => (
                      <span className="mr-1">
                        <Badge
                          name={`${formatMessage({ id: `days.short.${day}` })}`}
                          color="green"
                        />
                      </span>
                    ))} */}

                    {free_days &&
                      free_days?.length > 0 &&
                      days.map((day) => (
                        <span className="mr-1">
                          <Badge
                            name={`${formatMessage({
                              id: `days.short.${day}`,
                            })}`}
                            color={
                              free_days?.includes(
                                day as
                                  | 'MONDAY'
                                  | 'TUESDAY'
                                  | 'WEDNESDAY'
                                  | 'THURSDAY'
                                  | 'FRIDAY'
                                  | 'SATURDAY'
                                  | 'SUNDAY',
                              )
                                ? 'green'
                                : 'gray'
                            }
                          />
                        </span>
                      ))}
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderSection7 = (
    street?: string | null,
    city?: string | null,
    country?: string | null,
  ) => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Localisation</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {street}, {city}, {country}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* MAP */}
        <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3 ring-1 ring-black/10 rounded-xl z-0">
          <div className="rounded-xl overflow-hidden z-0">
            <iframe
              title="x"
              width="100%"
              height="100%"
              loading="lazy"
              allowFullScreen
              referrerPolicy="no-referrer-when-downgrade"
              src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyCeJ3Ld-S32gKyaCMQ0tj73pEepaO-pns4&q=${street}+${city}+${country}`}
            ></iframe>
          </div>
        </div>
      </div>
    );
  };

  const renderSection8 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Information sur cet espace</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT 
        <div>
          <h4 className="text-lg font-semibold">Annulation</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            Remboursement à hauteur de 50% du montant total de la reservation
            quand l'annulation à lieu 48h avant le début de la journée de
            location <br />
            Pour toute annulation à moins de 48h avant le début de la journée de
            location, aucun remboursement ne sera effectué.
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />*/}

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Règlement intérieur</h4>
          <div className="prose sm:prose">
            <ul className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2">
              <li>Il est strictement interdit de fumer dans cet espace.</li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const [price, setPrice] = useState(0);
  const [tarif, setTarif] = useState('');

  const computePrice = (
    morning: boolean,
    afternoon: boolean,
    evening: boolean,
  ) => {
    let price = 0;
    let tarif = '';
    if (morning) price += 49;
    if (afternoon) price += 49;
    if (evening) price += 39;

    if (
      (morning && !afternoon && !evening) ||
      (!morning && afternoon && !evening)
    ) {
      price = 49;
      tarif = 'demi-journée';
    } else if (morning && afternoon && !evening) {
      price = 79;
      tarif = 'journée';
    } else if (morning && afternoon && evening) {
      price = 99;
      tarif = 'journée + soirée';
    } else if (
      (!morning && afternoon && evening) ||
      (morning && !afternoon && evening)
    ) {
      price = 69;
      tarif = 'demi-journée + soirée';
    } else if (!morning && !afternoon && evening) {
      price = 39;
      tarif = 'soirée';
    }

    setPrice(price);
    setTarif(tarif);
  };

  const renderSidebar = (fleexoff: boolean | undefined | null) => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl">
        <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
          <StayDatesRangeInput
            className="flex-1 z-[11]"
            availableDates={fleexoff ? availablesDates : null}
            onChangeSelectedDate={onChangeSelectedDate}
            selectedDate={selectedDate}
            free_days={currentProperty?.data?.free_days}
          />
          {fleexoff && (
            <>
              <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
              <GuestsInput className="flex-1" computePrice={computePrice} />
            </>
          )}
        </form>

        {
          fleexoff && (
            <div className="flex flex-col space-y-4">
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                {selectedDate && tarif ? (
                  <span>
                    {selectedDate.toLocaleDateString('fr-FR', {
                      month: 'short',
                      day: '2-digit',
                    })}{' '}
                    - {tarif}
                  </span>
                ) : (
                  '-'
                )}
                <span>{price}€</span>
              </div>
              <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
              <div className="flex justify-between font-semibold">
                <span>Total</span>
                <span>{price}€</span>
              </div>
            </div>
          )
          /* SUM */
        }
        {/* SUBMIT */}
        <ButtonPrimary
          // href={'/contact'}
          onClick={() => {
            ReactGA.event({
              category: 'User event',
              action: 'Click on book button',
            });
            router(`/booking?id=${currentProperty?.data?.id}`);
          }}
        >
          Réserver
        </ButtonPrimary>
      </div>
    );
  };

  const images: string[] =
    currentProperty?.data?.assets && currentProperty?.data?.assets.length > 0
      ? currentProperty?.data?.assets?.map(
          (asset) =>
            `https://d10qdsce7xisc3.cloudfront.net/${currentProperty?.data?.id}/${asset}`,
        )
      : [];

  return (
    <>
      {currentProperty ? (
        <div className="nc-ListingStayDetailPage">
          <header className="rounded-md sm:rounded-xl">
            <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
              <div
                className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer "
                onClick={handleOpenModalImageGallery}
              >
                <img
                  className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                  src={images[0]}
                  alt=""
                  sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
                />
                <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
              </div>
              {images
                .filter((_, i) => i >= 1 && i < 5)
                .map((item, index) => (
                  <div
                    key={index}
                    className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                      index >= 3 ? 'hidden sm:block' : ''
                    }`}
                  >
                    <div className="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5">
                      <img
                        className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                        src={item || ''}
                        alt=""
                        sizes="400px"
                      />
                    </div>

                    <div
                      className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                      // onClick={handleOpenModalImageGallery}
                    />
                  </div>
                ))}

              {/* <button
                className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 hover:bg-neutral-200 z-10"
                onClick={handleOpenModalImageGallery}
              >
                <Squares2X2Icon className="w-5 h-5" />
                <span className="ml-2 text-neutral-800 text-sm font-medium">
                  Voir
                </span>
              </button> */}
            </div>
          </header>

          <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">
            <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
              {renderSection1(
                currentProperty?.data?.name,
                currentProperty?.data?.city,
                currentProperty?.data?.country,
                currentProperty?.data?.size,
                currentProperty?.data?.fleexoff,
                currentProperty?.data?.users,
              )}
              <div className="block lg:hidden ">
                <div className="listingSectionSidebar__wrap shadow-xl">
                  <ButtonPrimary
                    // href={'/contact'}
                    onClick={() => {
                      ReactGA.event({
                        category: 'User event',
                        action: 'Click on book button',
                      });
                      router(`/booking?id=${currentProperty?.data?.id}`);
                    }}
                  >
                    Réserver
                  </ButtonPrimary>
                </div>{' '}
              </div>
              {renderSection2(currentProperty?.data?.description)}
              {renderSection3(currentProperty?.data?.amenities)}
              {renderSection4(
                currentProperty?.data?.price_per_day,
                currentProperty?.data?.free_days,
              )}
              {renderSection7(
                currentProperty?.data?.street,
                currentProperty?.data?.city,
                currentProperty?.data?.country,
              )}
              {renderSection8()}
              <div className="block lg:hidden ">
                <div className="listingSectionSidebar__wrap shadow-xl">
                  <ButtonPrimary
                    // href={'/contact'}
                    onClick={() => {
                      ReactGA.event({
                        category: 'User event',
                        action: 'Click on book button',
                      });
                      router(`/booking?id=${currentProperty?.data?.id}`);
                    }}
                  >
                    Réserver
                  </ButtonPrimary>
                </div>{' '}
              </div>
            </div>

            <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
              <div className="sticky top-28">
                {renderSidebar(currentProperty?.data?.fleexoff)}
              </div>
            </div>
          </main>
        </div>
      ) : null}
    </>
  );
};

export default function ListingStayDetailPage() {
  return (
    <DetailPagetLayout>
      <StayDetailPageContainer />
    </DetailPagetLayout>
  );
}
