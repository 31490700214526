import { GlobalState } from "./global-state";

export type Action = { type: 'SET_USER', payload: any };

export const reducer = (state: GlobalState, action: Action): GlobalState => {
    switch (action.type) {
        case 'SET_USER':
            return { ...state, user: action.payload };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
}