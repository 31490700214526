import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import { PropertyDto } from "api/generated-api-client";
import Badge from "shared/Badge/Badge";

export interface StayCardProps {
  className?: string;
  data: PropertyDto;
  isPropertyBookmarked?: boolean;
  onBookmarkClick?: (id: string) => void;
  size?: "default" | "small";
  displayBookmarkIcon?: boolean;
}

const StayCard: FC<StayCardProps> = ({
  size = "default",
  isPropertyBookmarked,
  className = "",
  onBookmarkClick,
  data: dataProp,
  displayBookmarkIcon,
}) => {
  const {
    id,
    name,
    street,
    zipcode,
    city,
    size: propertySize,
    assets,
    fleexoff,
    price_per_day,
  } = dataProp;

  const images: string[] =
    assets && assets.length > 0
      ? assets?.map(
          (asset) => `https://d10qdsce7xisc3.cloudfront.net/${id}/${asset}`
        )
      : [];

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <GallerySlider
          uniqueID={`StayCard_${id}`}
          ratioClass="aspect-w-4 aspect-h-3 "
          galleryImgs={images}
          href={"/"}
          eventId={id}
        />

        {displayBookmarkIcon && (
          <BtnLikeIcon
            isLiked={isPropertyBookmarked}
            onClick={() => onBookmarkClick && onBookmarkClick(id)}
            className="absolute right-3 top-3 z-[1]"
          />
        )}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2" style={{ height: "100px" }}>
          <span className="text-sm text-neutral-500 dark:text-neutral-400">
            {/* {listingCategory.name} · {bedrooms} beds*/}
            Bureau · {propertySize}m²
          </span>
          <div className="flex items-center space-x-2">
            {fleexoff && <Badge name="Fleexoff" color="green" />}
            <h2
              className={` font-medium capitalize ${
                size === "default" ? "text-lg" : "text-base"
              }`}
            >
              <span className="line-clamp-1">{name}</span>
            </h2>
          </div>
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            {size === "default" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            )}
            <span className="">
              {street} {zipcode} {city}
            </span>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center mt-5">
          <span className="text-base font-semibold">
            {size === "default" && (
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                A partir de :{" "}
              </span>
            )}
            {price_per_day ?? "49"}€
            {size === "default" && (
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal"></span>
            )}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      {renderSliderGallery()}
      <Link to={{ pathname: "/listing-stay-detail", search: `?id=${id}` }}>
        {renderContent()}
      </Link>
    </div>
  );
};

export default StayCard;
