import { Dispatch, PropsWithChildren, createContext, useReducer } from "react";
import { GlobalState, defaultGlobalState } from "./global-state";
import { Action, reducer } from "./reducer";

export const StateContext = createContext<GlobalState | undefined>(undefined);
export const DispatchContext = createContext<Dispatch<Action> | undefined>(
  undefined
);

interface GlobalContextProviderProps {
  initialState?: GlobalState;
}

export function GlobalStoreProvider({
  children,
  initialState = defaultGlobalState,
}: PropsWithChildren<GlobalContextProviderProps>) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
}
