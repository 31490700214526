import SplashSection from 'components/SplashSection/SplashSection';
import SectionDiscoverTenant from 'components/SectionDiscoverTenant/SectionDiscoverTenant';
import SectionDiscoverLandlord from 'components/SectionDiscoverLandlord/SectionDiscoverLandlord';
import SectionHowItWork from 'components/SectionHowItWork/SectionHowItWork';

import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism';

function PageHome() {
  return (
    <div className="relative overflow-hidden">
      <BgGlassmorphism />

      <div className="container relative">
        <SplashSection />
        <SectionDiscoverTenant />
        <SectionDiscoverLandlord />
        <SectionHowItWork />
      </div>
    </div>
  );
}

export default PageHome;
