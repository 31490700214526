import { Tab } from '@headlessui/react';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import React, { FC, Fragment, useState } from 'react';
import visaPng from 'images/vis.png';
import mastercardPng from 'images/mastercard.svg';
import { GuestsObject } from 'components/HeroSearchForm/type';
import StartRating from 'components/StartRating/StartRating';
import NcModal from 'shared/NcModal/NcModal';
import ModalSelectDate from 'components/ModalSelectDate';
import converSelectedDateToString from 'utils/converSelectedDateToString';
import ModalSelectGuests from 'components/ModalSelectGuests';
import Label from 'components/Label/Label';
import Input from 'shared/Input/Input';
import Textarea from 'shared/Textarea/Textarea';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetProperty } from 'api/queries';
import emailjs from '@emailjs/browser';

export interface CheckOutPagePageMainProps {
  className?: string;
}

const CheckOutPagePageMain: FC<CheckOutPagePageMainProps> = ({
  className = '',
}) => {
  const { formatMessage } = useIntl();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const location = useLocation();
  const router = useNavigate();

  const currentProperty = useGetProperty(params.get('id')!);

  const [startDate] = useState<Date | null>(new Date('2023/02/06'));
  const [endDate] = useState<Date | null>(new Date('2023/02/23'));

  const [guests] = useState<GuestsObject>({
    guestAdults: 2,
    guestChildren: 1,
    guestInfants: 1,
  });

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [name, setName] = useState('');

  const [notSent, setSent] = useState(true);

  const handleSubmit = () => {
    emailjs.send(
      'service_9y8en9e',
      'template_5va7hkl',
      {
        email: email,
        message: message,
        name: name,
        id: currentProperty?.data?.id,
      },
      'I8jGdIx__fwaH3Wsm',
    );
    setSent(false);
  };

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full sm:w-40">
            <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
              <img
                alt=""
                className="absolute inset-0 object-cover"
                sizes="200px"
                src={
                  currentProperty?.data?.assets &&
                  currentProperty?.data?.assets.length > 0
                    ? `https://d10qdsce7xisc3.cloudfront.net/${currentProperty?.data?.id}/${currentProperty?.data?.assets[0]}`
                    : ''
                }
              />
            </div>
          </div>
          <div className="py-5 sm:px-5 space-y-3">
            <div>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                {currentProperty?.data?.city}
              </span>
              <span className="text-base font-medium mt-1 block">
                {currentProperty?.data?.name}
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>A partir de : </span>
            <span>{currentProperty?.data?.price_per_day ?? ''}€</span>
          </div>
        </div>
      </div>
    );
  };

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Demande de réservation
        </h2>
        <div>
          {notSent ? (
            <div className="grid grid-cols-1 gap-6">
              <label className="block">
                <Label>Nom</Label>

                <Input
                  placeholder="Jean Dupont"
                  type="text"
                  className="mt-1"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </label>
              <label className="block">
                <Label>Email</Label>

                <Input
                  type="email"
                  placeholder="example@example.com"
                  className="mt-1"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </label>
              <label className="block">
                <Label>Message</Label>

                <Textarea
                  className="mt-1"
                  rows={6}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </label>
              <div>
                <ButtonPrimary onClick={() => handleSubmit()}>
                  Envoyer
                </ButtonPrimary>
              </div>
            </div>
          ) : (
            <div className="grid grid-cols-1 gap-6">
              Demande de contact envoyée
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-CheckOutPagePageMain ${className}`}>
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
        <div className="hidden lg:block flex-grow">{renderSidebar()}</div>
      </main>
    </div>
  );
};

export default CheckOutPagePageMain;
