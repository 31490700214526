import { FC } from 'react';
import SectionGridFilterCard from './SectionGridFilterCard';
import { useListProperties } from 'api/queries';

export interface ListingStayPageProps {
  className?: string;
}

const ListingStayPage: FC<ListingStayPageProps> = ({ className = '' }) => {
  const { data } = useListProperties(1, 5);

  return (
    <div
      className={`nc-ListingStayPage relative overflow-hidden ${className}`}
      data-nc-id="ListingStayPage"
    >
      <div className="container relative overflow-hidden mt-5">
        <SectionGridFilterCard className="pb-24 lg:pb-28" data={data} />
      </div>
    </div>
  );
};

export default ListingStayPage;
