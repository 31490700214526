import Cookies from "js-cookie";

export function useLocalTokens() {
  const getLocalToken = () => Cookies.get("token");
  const getLocalTokenExpires = () => Cookies.get("tokenExpires");
  const getLocalRefreshToken = () => Cookies.get("refreshToken");
  const removeLocalToken = () => Cookies.remove("token");
  const removeLocalTokenExpires = () => Cookies.remove("tokenExpires");
  const removeLocalRefreshToken = () => Cookies.remove("refreshToken");
  const removeAllLocalTokens = () => {
    Cookies.remove("token");
    Cookies.remove("tokenExpires");
    Cookies.remove("refreshToken");
  };
  const setLocalToken = (token: string) => Cookies.set("token", token);
  const setLocalTokenExpires = (tokenExpires: string) =>
    Cookies.set("tokenExpires", tokenExpires);
  const setLocalRefreshToken = (refreshToken: string) =>
    Cookies.set("refreshToken", refreshToken);

  return {
    getLocalToken,
    getLocalTokenExpires,
    getLocalRefreshToken,
    removeLocalToken,
    removeLocalTokenExpires,
    removeLocalRefreshToken,
    removeAllLocalTokens,
    setLocalToken,
    setLocalTokenExpires,
    setLocalRefreshToken,
  };
}
